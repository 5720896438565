import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { getAnnualProfitStatistics } from "../../../helpers/backend_helper";
import "./AnnualStats.css";
import Widgets from "../../../Components/Widgets/Widgets";
import SalesForecast from "../../../Components/Graph/Custom/SalesForecast";

const AnnualStats = ({filters, total_profit}) => {
    const [stats, setStats] = useState({
        profit: 0,
        deals: 0,
        daily_average_income: 0,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Загружаем данные через API
        const fetchStats = () => {
            getAnnualProfitStatistics(filters)
                .then((response) => {
                    setStats(response);
                })
                .catch((error) => {})
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchStats();
    }, [filters]);

    if (loading) {
        return <div className="loading">Загрузка...</div>;
    }

    return (
        <Row>
            <Col xl={0} xxl={1}></Col>
            <Col xl={6} xxl={5}>
                <Widgets stats={stats} total_profit={total_profit}/>
            </Col>
            <Col xl={1}></Col>
            <Col xl={5} xxl={4}>
                <SalesForecast filters={filters} total_profit={total_profit}/>
            </Col>
            <Col xl={0} xxl={1}></Col>
        </Row>
    );
};

export default AnnualStats;


/*
import React, { useState, useEffect } from "react";
import "./AnnualStats.css";
import {Col, Row} from "reactstrap";
import {getAnnualProfitStatistics} from "../../../helpers/backend_helper";
import Widgets from "../../../Components/Widgets/Widgets"; // Подключаем стили

const AnnualStats = () => {
    const [stats, setStats] = useState({
        annual_profit: 0,
        annual_deals: 0,
        daily_average_income: 0,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Загружаем данные через API
        const fetchStats = () => {
            getAnnualProfitStatistics()
                .then((response) => {
                    setStats(response);
                })
                .catch((error) => {})
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchStats();
    }, []);

    if (loading) {
        return (
            <div className="loading">Loading...</div>
        );
    }

    return (
        <Row>
            <Col xl={5} className="annual-stats-container">
                <div className="stat-block">
                    <h3>Годовая прибыль</h3>
                    <p>{stats.annual_profit} ₸</p>
                </div>
                <div className="stat-block">
                    <h3>Количество заказов за год</h3>
                    <p>{stats.annual_deals}</p>
                </div>
                <div className="stat-block">
                    <h3>Дневная средняя прибыль</h3>
                    <p>{stats.daily_average_income} ₸</p>
                </div>
                <div className="stat-block">
                    {/!* Пустой блок *!/}
                    <h3>Пустой блок</h3>
                    <p>Скоро здесь появится информация</p>
                </div>
            </Col>
            <Widgets stats={stats}/>
        </Row>
    );
};

export default AnnualStats;
*/
