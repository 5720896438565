import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import { Button } from "reactstrap";
import { createReceptionDraft } from "../../helpers/backend_helper";
import getCurrentDateTime from "../../slices/invoice/utils";


function WriteOffsCreate() {
    const navigate = useNavigate();

    const handleCreateReception = () => {
        const receptionData = {
            draft: true,
            write_off: true,
            received_date: getCurrentDateTime()
        };

        createReceptionDraft(receptionData)
            .then((response) => {

                const createdReceptionId = response.id;

                navigate(`/writeoffs-new/${createdReceptionId}`);
            })
            .catch((error) => {
                console.error("Error creating reception:", error);
            });
    };

    return (
        <div>

            <Button
                onClick={handleCreateReception}
                color="danger"
                className="add-btn"
                id="create-btn"
                style={{lineHeight: 1, minHeight: "40px"}}
            >
                <i className="ri-add-line align-bottom me-1"></i> Добавить списание
            </Button>{" "}

        </div>

    );
}

export default WriteOffsCreate;
