import React, {useState, useEffect} from 'react';
import ReactApexChart from "react-apexcharts";
import {getProfitForecast} from "../../../helpers/backend_helper";

// export default SalesForecastCharts;


/*const SalesForecastCharts = ({ data }) => {
    const [chartData, setChartData] = useState({
        series: [
            { name: "Текущая прибыль", data: [] },
            { name: "Прогнозируемая прибыль", data: [] },
        ],
        categories: [],
    });

    useEffect(() => {
        if (data) {
            const updatedSeries = [
                { name: "Текущая прибыль", data: data.currentProfit },
                { name: "Прогнозируемая прибыль", data: data.forecastProfit },
            ];
            setChartData({
                series: updatedSeries,
                categories: data.months,
            });
        }
    }, [data]);

    const options = {
        chart: {
            type: 'bar',
            height: 341,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: '65%' },
        },
        xaxis: {
            categories: chartData.categories,
            title: {
                text: 'Месяцы',
                style: { fontSize: '12px', fontWeight: 400 },
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => `${value}k ₸`,
            },
            title: {
                text: 'Прибыль ₸',
                style: { fontSize: '12px', fontWeight: 400 },
            },
        },
        colors: ['#34c38f', '#f1b44c'],
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            markers: { width: 10, height: 10 },
        },
        fill: { opacity: 1 },
    };

    return (
        <ReactApexChart
            options={options}
            series={chartData.series}
            type="bar"
            height="341"
        />
    );
};*/
// const parseNumber = (value) => {
//     if (!value) return 0;
//     return Number(value.replace(/,/g, "").replace(/\s/g, ""));
// };


const SalesForecastCharts = ({ data, total_profit }) => {

    const [chartData, setChartData] = useState({
        series: [
            { name: "Оборот", data: [] },
            { name: "Прибыль", data: [] },
            { name: "Расходы", data: [] },
        ],
        categories: [],
    });



    // Функция для преобразования данных в числовой формат
    // const parseNumber = (value) => {
    //     if (!value) return 0;
    //     return Number(value.replace(/,/g, ""));
    // };

    useEffect(() => {
        if (total_profit) {
            total_profit = (total_profit / 1000).toFixed(1);
        }
        if (data && data.months && data.turnover && total_profit && data.expenses) {  //  && data.profit
            const updatedSeries = [
                { name: "Оборот", data: data.turnover }, // .map(parseNumber)
                { name: "Прибыль", data: [String(total_profit)] }, // data.profit
                { name: "Расходы", data: data.expenses },
            ];
            setChartData({
                series: updatedSeries,
                categories: data.months,
            });
        }
    }, [data, total_profit]);

    if (!data || !data.months || !data.turnover || !total_profit || !data.expenses) { // !data.profit
        return <div className="loading">Данные загружаются...</div>;
    }

    const options = {
        chart: {
            type: 'bar',
            height: 341,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: '65%' },
        },
        xaxis: {
            categories: chartData.categories,
            title: {
                text: 'Дата',
                style: { fontSize: '12px', fontWeight: 400 },
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => `${value}k ₸`,
            },
            /*
            formatter: (value) => {
                    // Преобразуем значение в тысячи
                    const valueInThousands = value / 1000;
                    return `${Number(valueInThousands.toFixed(1))}k ₸`; // Округляем до одного знака после запятой
                },
            */
            title: {
                text: 'Сумма ₸',
                style: { fontSize: '12px', fontWeight: 400 },
            },
        },
        colors: ['#34c38f', '#f1b44c', '#f46a6a'],
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            markers: { width: 10, height: 10 },
        },
        fill: { opacity: 1 },
    };

    return (
        <ReactApexChart
            options={options}
            series={chartData.series}
            type="bar"
            height="341"
        />
    );
};



const SalesForecast = ({filters, total_profit}) => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Загружаем данные через API
        const fetchStats = () => {
            getProfitForecast(filters)
                .then((response) => {
                    setChartData(response);
                })
                .catch((error) => {})
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchStats();
    }, [filters]);

    if (!chartData) {
        return <p>Загрузка...</p>;
    }

    return (
        <div>
            {/*<h3>Sales Forecast</h3>*/}
            <SalesForecastCharts data={chartData} total_profit={total_profit}/>
        </div>
    );
};

export default SalesForecast;


/*const SalesForecast = () => {
    const [data, setData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState("Nov 2021");

    useEffect(() => {
        // Здесь эмулируем API-запрос
        const mockData = {
            months: ["Oct 2021", "Nov 2021", "Dec 2021", "Jan 2022"],
            currentProfit: [10, 15, 20, 18],
            forecastProfit: [12, 18, 22, 20],
        };
        setData(mockData);
    }, []);

    const handleMonthChange = (month) => {
        setSelectedMonth(month);
        // В дальнейшем можно будет отправлять запрос к API
    };

    return (
        <div>
            {/!*<h4>Sales Forecast</h4>*!/}
            <select
                value={selectedMonth}
                onChange={(e) => handleMonthChange(e.target.value)}
            >
                <option value="Oct 2021">Oct 2021</option>
                <option value="Nov 2021">Nov 2021</option>
                <option value="Dec 2021">Dec 2021</option>
                <option value="Jan 2022">Jan 2022</option>
            </select>
            {data && <SalesForecastCharts data={data} />}
        </div>
    );
};

export default SalesForecast;*/
