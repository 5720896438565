import React, {useState, useEffect, useCallback, useMemo} from "react";

import Flatpickr from "react-flatpickr";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form, FormFeedback, CardHeader
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import styles from "./Expenses.module.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import DeleteModal from "../../../Components/Common/DeleteModal";
import {
    getExpensesApi,
    createExpensesApi,
    deleteExpensesApi,
    getExpenseCategoriesApi,
    createExpenseCategoriesApi,
    deleteExpenseCategoriesApi,
    updateStatusApi,
    deleteStatusApi, updateExpenseCategoriesApi, getAccountsApi
} from "../../../helpers/backend_helper";
import {sum} from "lodash";
import {useFormik} from "formik";
import * as Yup from "yup";
import CustomPieChart from "./PieChart";
import {DoughnutChart} from "../../../Components/Charts/Echarts/ECharts";
import {PieChart} from "../../../Components/Charts/Echarts/ECharts.js";

function Expenses() {
    const [expensesCategories, setExpensesCategories] = useState([]);
    const [expensesData, setExpensesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [errors, setErrors] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [isModalCategory, setIsModalCategory] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [accounts, setAccounts] = useState({});

    const hasPermissions = (permissions) => {
        return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access)
    };
    const getAccessObjects = () => {
        return String(accounts?.results && accounts.current && accounts?.results[accounts.current]?.access_objects);
    };

    useEffect(() => {
    const fetchAccounts = () => {
      getAccountsApi()
      .then((data) => {
        setAccounts(data);
      })
      .catch(() => {
        // toast.error("Не удалось получить список аккаунтов", {
        //   position: "top-right",
        // });
      });
    };
    fetchAccounts();
    }, []);

    const fetchExpensesCategories = () => {
        let data = {};
        if ('dates' in filters) {
            Object.keys(filters['dates']).forEach(key => {
                data[key] = filters['dates'][key];
            });
        }
        getExpenseCategoriesApi(data)
          .then((response) => {
            setExpensesCategories(response);
          })
          .catch(() => {});
    };

    const fetchExpenses = async () => {  // Function to fetch expenses data
      try {
        setLoading(true);
        let data = {};
        if ('dates' in filters) {
          Object.keys(filters['dates']).forEach(key => {
            data[key] = filters['dates'][key];
          });
        }
        const response = await getExpensesApi(data);
        setExpensesData(response);
      } catch (error) {
        toast.error('Не удалось получить данные о расходах');
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
        fetchExpenses();
        fetchExpensesCategories();
    }, [filters]);

    // useEffect(() => {
    //     fetchExpensesCategories();
    // }, []);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };
    const handleModalCategory = () => {
        setIsModalCategory(true);
    };
    const handleProductChange = () => {
        setIsModalOpen(true);
    };
    const handleCloseModalCategory = () => {
        setIsModalCategory(false);
    }
    const handleCloseModal = async () => {
        setIsModalOpen(false);
    };

    const [isAddCategory, setIsAddCategory] = useState(false);
    // const [isModalCategoryOpen, setIsModalCategoryOpen] = useState(false);
    // const handleCategoryClose = () => {
    //   setIsModalCategoryOpen(false);
    // };
    const handleCloseAddCategory = async () => {
        setIsAddCategory(false);
    };
    const handleAddCategory = () => {
      setErrors({});
      // setStatusInputName("");
      // setStatusInputMark("neutral");
      setIsAddCategory(true);
    };

    const filteredData = expensesData?.length > 0 && expensesData.filter(item => {
        const price = parseFloat(item.total);
        return ((minPrice === '' || price >= parseFloat(minPrice)) &&
            (maxPrice === '' || price <= parseFloat(maxPrice))) &&
            (selectedCategory === '' || item.category_title === selectedCategory)
    });


    ///////////////////////////
    const [deleteModal, setDeleteModal] = useState(false);
    const deleteMultiple = async () => {
        const checkall = document.getElementById("checkBoxAll");
        for (const element of selectedCheckBoxDelete) {
            await deleteExpensesApi({ id: element.value })
                .then(() => {})
                .catch(() => {
                    toast.error("Не удалось удалить запись расхода", { position: "top-right" });
                });
            setTimeout(() => {
                toast.clearWaitingQueue();
            }, 3000);
        }
        setDeleteModal(false);
        fetchExpenses();
        fetchExpensesCategories();
        toast.success("Выбранные заказы были успешно удалены", {
            position: "top-right",
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
        checkedAll();
    };
    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0
            ? setIsMultiDeleteButton(true)
            : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const [initialDate] = useState(new Date());
    const toLocale = (date) => {
        return date.toLocaleDateString(
            'ru',
            { year: 'numeric', month: '2-digit', day: '2-digit' }
        )
        .replace('.', '-')
        .replace('.', '-')
        .split('-')
        .reverse()
        .join('-');
    };

    const validationExpenses = useFormik({
        enableReinitialize: true,
        initialValues: {
          date: initialDate,
          total: "",
          category: expensesCategories?.length > 0 && String(expensesCategories[0].id) || "",
          comment: "",
        },
        validationSchema: Yup.object({
          date: Yup.date().required("Обязательное поле"),
          total: Yup.number().required("Обязательное поле"),
          category: Yup.string().required("Обязательное поле"),
        }),
        onSubmit: async (values) => {
          const newExpenseData = {
            date: toLocale(values.date),
            total: values.total,
            category: values.category,
            comment: values.comment
          };

          createExpensesApi(newExpenseData)
            .then((data) => {
              setErrors({});
              validationExpenses.resetForm();
              fetchExpenses();
              fetchExpensesCategories();
              setIsModalOpen(false);
              toast.success("Запись расхода была создана", {position: "top-right"});
            })
            .catch((error) => {
              if (
                error?.response?.data?.errors &&
                String(error?.response?.status).startsWith("4")
              ) {
                let err = error.response.data.errors;
                if (err.error) {
                  toast.error(err.error[0], { position: "top-right" });
                }
                if (err) {
                  setErrors(err);
                } else {
                  setErrors({});
                }
              } else {
                toast.error("Не удалось создать запись расхода", {
                  position: "top-right",
                });
              }
            });
          if (!errors) {
            validationExpenses.resetForm();
          }
        },
    });

    const validationCategory = useFormik({
        enableReinitialize: true,
        initialValues: {
          title: "",
        },
        validationSchema: Yup.object({
          title: Yup.string().required("Обязательное поле"),
        }),
        onSubmit: async (values) => {
          const newCategoryData = {
            title: values.title,
          };
          createExpenseCategoriesApi(newCategoryData)
            .then((data) => {
              setErrors({});
              validationCategory.resetForm();
              fetchExpensesCategories();
              setIsModalCategory(false);
              toast.success("Категория была создана", {position: "top-right"});
            })
            .catch((error) => {
              if (
                error?.response?.data?.errors &&
                String(error?.response?.status).startsWith("4")
              ) {
                let err = error.response.data.errors;
                if (err.error) {
                  toast.error(err.error[0], { position: "top-right" });
                }
                if (err) {
                  setErrors(err);
                } else {
                  setErrors({});
                }
              } else {
                toast.error("Не удалось создать категорию", {
                  position: "top-right",
                });
              }
            });
          if (!errors) {
            validationCategory.resetForm();
          }
        },
    });

    const formattedDate = (unformattedDate) => {
        const [datePart, timePart] = unformattedDate.split('T');
        const [year, month, day] = datePart.split('-');
        // const timeWithoutZ = timePart.replace('Z', '');
        // const [hours, minutes, seconds] = timeWithoutZ.split(':');
        return `${day}-${month}-${year}`;
    };

    const updateCategory = async (categoryData) => {
      try {
        categoryData.title = categoryData.title.trim();
        setErrors({});
        const response = await updateExpenseCategoriesApi(categoryData);
        // setOrderData(response);
      } catch (error) {
        if (error?.response?.data?.errors && String(error?.response?.status).startsWith('4')) {
          setErrors(error.response.data.errors);
        } else {
          toast.error("Не удалось обновить категорию");
        }
      }
    };
    const handleDeleteCategory = async (id) => {
      try {
        await deleteExpenseCategoriesApi({id});
        toast.success("Категория расходов успешно удалена");
        fetchExpenses();
        fetchExpensesCategories();
      } catch (error) {
        toast.error("Не удалось удалить категорию");
      }
    };

    document.title = "CRMBEK : Расходы";

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <div className="page-content pb-5">
                {hasPermissions(['owner']) ? (
                <Container fluid>
                    {/*<div>
                        <Button
                            // style={{ paddingLeft: '8px', marginLeft: '8.3%' }}
                            onClick={handleProductChange}
                            color="primary"
                            className="add-btn"
                            id="create-btn"
                        >
                            <i className="ri-add-line align-bottom me-1 fs-15"></i><span style={{ fontSize: '15px', fontWeight: 'bold' }}>Расход</span>
                        </Button>{" "}
                    </div>*/}
                    <Col lg={12} style={{ margin: "0 auto" }}>
                        <Card className="mt-0" style={{backgroundColor: 'transparent'}}>
                            <CardBody>
                                <div className="listjs-table" id="customerList">
                                    <Row className="g-4 mb-3">
                                        <Col className={styles.width}>
                                            <section className={styles.filter_section}>
                                                <h3 className={styles.title}>История</h3>
                                                <div className={styles.filter_element_date}>
                                                    <div className={`input-group ${styles.date_range}`}>
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="ri-calendar-2-line"></i>
                                                        </span>
                                                        <Flatpickr
                                                            style={{minWidth: '281px'}}
                                                            placeholder="Выберите дату/время"
                                                            className={`form-control`}
                                                            options={{
                                                                mode: "range",
                                                                enableTime: true, // Включение выбора времени
                                                                time_24hr: true, // Отображение времени в 24-часовом формате
                                                                dateFormat: "d M, Y H:i", // Формат с временем
                                                                locale: Russian,
                                                                onChange: function(selectedDates, dateStr, instance) {
                                                                    // Форматирование даты и времени
                                                                    const formattedDates = selectedDates.map(date =>
                                                                        date.toLocaleString(
                                                                            'ru',
                                                                            { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
                                                                        )
                                                                            .replace(',', '') // Убираем запятую после даты
                                                                            .replace('.', '-') // Заменяем точки на дефисы
                                                                            .replace('.', '-') // Заменяем вторую точку
                                                                            .replace(' ', 'T') // Приводим к ISO-формату
                                                                    );

                                                                    if (formattedDates.length === 2) { // Если выбрано два значения
                                                                        formattedDates[0] !== formattedDates[1]
                                                                            ? setFilters({
                                                                                ...filters,
                                                                                dates: {
                                                                                    range_start: formattedDates[0],
                                                                                    range_end: formattedDates[1],
                                                                                }
                                                                            })
                                                                            : setFilters({
                                                                                ...filters,
                                                                                dates: { date: formattedDates[0] }
                                                                            });
                                                                    }
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.sum_filter}>
                                                    <Label className="mb-0 fs-16">
                                                        Сумма
                                                    </Label>
                                                    <div className="d-flex justify-content-evenly">
                                                        <Input
                                                            type="number"
                                                            placeholder="От"
                                                            value={minPrice}
                                                            style={{ width: "100px", margin: "0 10px" }}
                                                            className={` ${styles.sum_filter_input}`}
                                                            onChange={e => setMinPrice(e.target.value)}
                                                        />
                                                        <Input
                                                            type="number"
                                                            placeholder="До"
                                                            style={{ width: "100px" }}
                                                            className={` ${styles.sum_filter_input}`}
                                                            value={maxPrice}
                                                            onChange={e => setMaxPrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter_element_category}>
                                                    <select className="form-select" value={selectedCategory} onChange={handleCategoryChange}>
                                                        <option style={{ fontWeight: "bold" }} value="">
                                                            Категория
                                                        </option>
                                                        {expensesCategories && expensesCategories.map((category, key) => (
                                                            <option key={key} value={category.title}>
                                                                {category.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </section>

                                            {/*{expensesCategories?.length > 0 && (
                                                <div className="card-body">
                                                    <PieChart data={expensesCategories} selectedCategory={selectedCategory} />
                                                </div>
                                            )}*/}
                                            {expensesCategories?.length > 0 && (
                                                <Col xxl="11" xl="11" xs="12" className="card-body pb-0 mt-5">
                                                    {/* mx-xl-5 px-xl-5 <PieChart data={expensesCategories} selectedCategory={selectedCategory} />*/}
                                                    <PieChart
                                                        data={expensesCategories}
                                                        selectedCategory={selectedCategory}
                                                        /*dataColors='[
                                                            "#FFBB93",
                                                            "#FF99B2",
                                                            "#C0CA33",
                                                            "#8BC34A",
                                                            "#29B6F6",
                                                            "#F06292",
                                                            "#FFB300",
                                                            "#FF7043",
                                                            "#90A4AE",
                                                            "#BA68C8",
                                                            "#FF8A65",
                                                            "#AA8A65",
                                                            "#A1887F",
                                                            "#FFD54F",
                                                            "#7986CB",
                                                            "#4DD0E1",
                                                            "#FF6384",
                                                            "#36A2EB",
                                                            "#FFCE56",
                                                            "#fffd9c"
                                                        ]'*/
                                                        /*dataColors='[
                                                            "#FF6384",
                                                            "#36A2EB",
                                                            "#FFCE56",
                                                            "#4BC0C0",
                                                            "#9966FF",
                                                            "#FF9F40",
                                                            "#32CD32",
                                                            "#FF0000",
                                                            "#800080",
                                                            "#00f080",
                                                            "#fffd9c",
                                                            "#92c1e8"
                                                        ]'*/
                                                        dataColors='[
                                                        "#FFBB93",


                                                            "#8BC34A",
                                                            "#29B6F6",
                                                            "#FF9AAE",
                                                            "#7EC8F3",
                                                            "#FFDF94",
                                                            "#8AD8D8",
                                                            "#BFA0FF",
                                                            "#FFBF85",
                                                            "#75E075",
                                                            "#FFBB93",
                                                            "#FF99B2",
                                                            "#C0CA33",

                                                            "#66F399",
                                                            "#FFFEBE",
                                                            "#B9D9EF",
                                                            "#A1887F",
                                                            "#FFD54F",
                                                            "#7986CB",
                                                            "#4DD0E1",
                                                            "#FF6384",
                                                            "#36A2EB",
                                                            "#FFCE56",
                                                            "#fffd9c"
                                                        ]'
                                                        />
                                                </Col>
                                            )}
                                        </Col>
                                    </Row>
                                    {/*<Row>
                                        <Col xl={12}>
                                            <Card>
                                                <CardHeader>
                                                    <h4 className="card-title mb-0">Pie Charts</h4>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <PieChart dataColors='[
                                                            "#FFBB93",
                                                            "#FF99B2",
                                                            "#C0CA33",
                                                            "#8BC34A",
                                                            "#29B6F6",
                                                            "#F06292",
                                                            "#FFB300",
                                                            "#FF7043",
                                                            "#90A4AE",
                                                            "#BA68C8",
                                                            "#FF8A65",
                                                            "#AA8A65",
                                                            "#A1887F",
                                                            "#FFD54F",
                                                            "#7986CB",
                                                            "#4DD0E1",
                                                            "#FF6384",
                                                            "#36A2EB",
                                                            "#FFCE56",
                                                            "#fffd9c"
                                                        ]'
                                                    />

                                                </div>
                                                "#FF6384",
                                                    "#36A2EB",
                                                    "#FFCE56",
                                                    "#4BC0C0",
                                                    "#9966FF",
                                                    "#FF9F40",
                                                    "#32CD32",
                                                    "#FF0000",
                                                    "#800080",
                                                    "#00f080",
                                            </Card>
                                        </Col>

                                        <Col xl={12}>
                                            <Card>
                                                <CardHeader>
                                                    <h4 className="card-title mb-0">Doughnut Chart</h4>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <DoughnutChart dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
                                                    <DoughnutChart dataColors='[
                                                            "#FFBB93",
                                                            "#FF99B2",
                                                            "#C0CA33",
                                                            "#8BC34A",
                                                            "#29B6F6",
                                                            "#F06292",
                                                            "#FFB300",
                                                            "#FF7043",
                                                            "#90A4AE",
                                                            "#BA68C8",
                                                            "#FF8A65",
                                                            "#AA8A65",
                                                            "#A1887F",
                                                            "#FFD54F",
                                                            "#7986CB",
                                                            "#4DD0E1",
                                                            "#FF6384",
                                                            "#36A2EB",
                                                            "#FFCE56",
                                                            "#fffd9c"
                                                        ]'
                                                    />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>*/}
                                </div>
                                <div className="listjs-table" id="customerList">
                                    {/*<Row className="g-4">*/}
                                    {/*    <Col className="col-sm-auto">*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <Col
                                            className="col-sm-auto"
                                            style={{ opacity: isMultiDeleteButton ? "1" : "0" }}
                                        >
                                            <button
                                                style={{ padding: "5px 14px", fontSize: "18px" }}
                                                className="btn btn-danger"
                                                onClick={() => setDeleteModalMulti(true)}
                                            >
                                                <i className="ri-delete-bin-2-line"></i>
                                            </button>
                                        </Col>
                                        <Col>
                                            <Button
                                                // style={{ paddingLeft: '8px', marginLeft: '8.3%' }}
                                                onClick={handleProductChange}
                                                color="primary"
                                                className="add-btn"
                                                id="create-btn"
                                            >
                                                <i className="ri-add-line align-bottom me-1 fs-15"></i><span style={{ fontSize: '15px', fontWeight: 'bold' }}>Расход</span>
                                            </Button>{" "}
                                        </Col>
                                    </Row>
                                    {filteredData?.length > 0 ? (
                                        <div className={`table-responsive table-card mt-3 ${styles.table_container}`}>
                                            <table
                                                id="customerTable"
                                                style={{ width: "100%" }}
                                                className={styles.table_expenses}
                                            >
                                                <thead className={styles.title_thead}>
                                                <tr className={styles.title_thead_expenses} >
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            id="checkBoxAll"
                                                            className="form-check-input"
                                                            onClick={() => checkedAll()}
                                                        />
                                                    </th>
                                                    <th>
                                                        <span className="py-1">
                                                            Дата
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="py-1">
                                                            Категория
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span>
                                                            Сумма
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span>
                                                            Комментарий
                                                        </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody style={{backgroundColor: 'white'}}>
                                                {filteredData.map((item, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="contactCheckBox form-check-input"
                                                                value={item.id}
                                                                onChange={() => deleteCheckbox()}
                                                            />
                                                        </td>
                                                        <td style={{minWidth: '92px'}}>
                                                            {formattedDate(item.date)}
                                                        </td>
                                                        <td>
                                                            {item.category_title}
                                                        </td>
                                                        <td>
                                                            {item.total_formatted}
                                                        </td>
                                                        <td >
                                                            {item.comment}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                                <tfoot className={styles.tfoot}>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {filteredData?.length > 0 && sum(filteredData.map((item) =>
                                                                item.total_formatted
                                                            )) || 0}
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    ) : <h4 className="text-center mb-5 mt-1" style={{opacity: 0.5}}>Данных по расходам не найдено</h4>}
                                </div>
                            </CardBody>
                            <ToastContainer closeButton={false} />
                        </Card>
                    </Col>
                </Container>
                    ) : (
                  <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
                )}

                <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validationExpenses.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalHeader toggle={handleCloseModal}>Новый расход</ModalHeader>
                        <ModalBody>
                            <div>
                                <div className={styles.filter_element_date_modal}>
                                    <div className={`input-group ${styles.date_range_modal}`}>
                                        <Label className="mb-0 fs-16">
                                            <h5>Дата</h5>
                                        </Label>
                                        <div className={`input-group ${styles.form_modal}`}>
                                            <span className="input-group-text " id="basic-addon1">
                                                <i className="ri-calendar-2-line"></i>
                                            </span>
                                            <Flatpickr
                                                placeholder="Выберите дату"
                                                className={`form-control`}
                                                options={{
                                                    dateFormat: "d M, Y",
                                                    locale: Russian,
                                                    defaultDate: validationExpenses.values.date,
                                                    onChange: (selectedDates) => {
                                                        validationExpenses.setFieldValue(
                                                            'date',
                                                            selectedDates[0]
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.sum_modal}>
                                    <Label className="mb-0 fs-16 ">
                                        <h5>Сумма</h5>
                                    </Label>
                                    <div className={styles.form_modal} style={{display: 'block'}}>
                                        <Input
                                            type="number"
                                            name="total"
                                            placeholder="Введите сумму"
                                            className={styles.sum_filter_input}
                                            onChange={validationExpenses.handleChange}
                                            onBlur={validationExpenses.handleBlur}
                                            value={validationExpenses.values.total || ""}
                                            invalid={
                                                validationExpenses.touched.total &&
                                                validationExpenses.errors.total
                                                    ? true
                                                    : !!errors?.total
                                            }
                                        />
                                        {validationExpenses.touched.total &&
                                          validationExpenses.errors.total ? (
                                            <FormFeedback type="invalid">
                                              {validationExpenses.errors.total}
                                            </FormFeedback>
                                          ) : errors && errors?.total ? (
                                            <FormFeedback type="invalid">
                                              <div>{errors.total}</div>
                                            </FormFeedback>
                                          ) : null
                                        }
                                    </div>
                                </div>
                                <div className={styles.modal_category}>
                                    <Label className="mb-0 fs-16">
                                        <h5>Категория</h5>
                                    </Label>
                                    <div className={styles.form_modal}>
                                        <select
                                            className="form-select"
                                            name="category"
                                            onChange={validationExpenses.handleChange}  // Use handleChange from Formik
                                            onBlur={validationExpenses.handleBlur}      // Use handleBlur from Formik
                                            value={validationExpenses.values.category}
                                        >
                                            {expensesCategories && expensesCategories.map((category, key) => (
                                                <option key={key} value={category.id}>
                                                    {category.title}
                                                </option>
                                            ))}
                                        </select>
                                        <div>
                                            <Button
                                                style={{marginLeft: '8px'}}
                                                onClick={() => setIsAddCategory(true)}
                                                color="primary"
                                                className="add-btn"
                                                id="create-btn"
                                            >
                                                <i className="ri-add-line align-bottom fs-15"></i>
                                            </Button>{" "}
                                        </div>
                                    </div>
                                    {/*{validationExpenses.touched.category &&*/}
                                    {/*  validationExpenses.errors.category ? (*/}
                                    {/*    <div className="error">*/}
                                    {/*      {validationExpenses.errors.category}*/}
                                    {/*    </div>*/}
                                    {/*      ) : errors && errors?.category ? (*/}
                                    {/*        <div className="error">*/}
                                    {/*            <div>{errors.category}</div>*/}
                                    {/*        </div>*/}
                                    {/*    ) : null*/}
                                    {/*}*/}
                                </div>
                                <div className="mt-3">
                                    <Label htmlFor="comment-field" className="form-label">
                                        <h5>Комментарий</h5>
                                    </Label>
                                    <Col xxl={3} md={6} style={{width: "100%"}}>
                                        <Input
                                            name="comment"
                                            id="comment-field"
                                            type="textarea"
                                            rows="3"
                                            style={{fontSize: "16px", maxHeight: "200px"}}
                                            className="form-control"
                                            placeholder="Введите комментарий..."
                                            onChange={validationExpenses.handleChange}
                                            onBlur={validationExpenses.handleBlur}
                                            value={validationExpenses.values.comment || ""}
                                            invalid={
                                                validationExpenses.touched.comment &&
                                                validationExpenses.errors.comment
                                                    ? true
                                                    : !!errors?.comment
                                            }
                                        />
                                    </Col>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="success">
                                Сохранить
                            </Button>{" "}
                        </ModalFooter>
                    </Form>
                </Modal>

                  <Modal isOpen={isAddCategory} toggle={handleCloseAddCategory}>
                    <ModalHeader toggle={handleCloseAddCategory}>
                      Настройка категорий расходов
                    </ModalHeader>

                      <ModalBody>
                        <Button
                            onClick={handleModalCategory}
                            color="success"
                            className="add-btn"
                            id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Добавить категорию
                        </Button>
                        <div
                            style={{ overflowY: "auto", height: "47vh", marginTop: "15px" }}
                        >
                          {expensesCategories?.length > 0 ? (
                              <ol style={{width: '100%', paddingLeft: 0}}>
                                {expensesCategories.map((category, index) => (
                                    <li className={styles.li_statuses} key={category.id} style={{ display: "flex" }}>
                                      {/*<div className={styles.mark_block}></div>*/}
                                      <Input
                                          maxLength={32}
                                          value={category?.title}
                                          onChange={(e) => {
                                            const updatedStatuses = [...expensesCategories];
                                            if (e.target.value.length < 1){
                                              e.target.value = updatedStatuses[index].title.slice(0, 1)
                                            }
                                            updatedStatuses[index].title = e.target.value;
                                            setExpensesCategories(updatedStatuses);
                                          }}
                                          onBlur={() => updateCategory(expensesCategories[index])}
                                      />
                                        <div className="px-2 d-flex align-items-center justify-content-center" style={{width: '160px'}}>
                                            <span>
                                                ({expensesData && expensesData.filter((item) => (
                                                    item.category === category.id
                                                )).length} записей)
                                            </span>
                                        </div>
                                      {/*<select
                                          value={category.mark || ""}
                                          disabled={Number(validationCategory.values.category) === category.id}
                                          onChange={(e) => {
                                            const updatedStatuses = [...expensesCategories];
                                            updatedStatuses[index].mark = e.target.value;
                                            setStatuses(updatedStatuses);
                                            updateStatus(updatedStatuses[index]);
                                          }}
                                          className={`form-select ${styles["select_width"]}`}
                                      >
                                        <option className={styles.clear_option} value="positive">Позитивный</option>
                                        <option className={styles.clear_option} value="neutral">Нейтральный</option>
                                        <option className={styles.clear_option} value="refusal">Отказ</option>
                                      </select>*/}
                                      {Number(validationCategory?.values?.category) !== category.id ? (
                                          <div
                                              className={styles.delete_inline}
                                              onClick={() => handleDeleteCategory(category.id)}
                                          >
                                            <img src="/delete-inline.svg" alt="delete"/>
                                          </div>
                                        ) : (<div className={styles.delete_inline}></div>)
                                      }
                                    </li>
                                ))}
                              </ol>
                          ) : (
                          <h5
                            style={{ lineHeight: "1.6", color: "grey" }}
                            className="text-center mt-3"
                          >
                            Категорий не найдено
                          </h5>
                        )}
                      </div>
                    </ModalBody>
                  </Modal>

                <Modal isOpen={isModalCategory} toggle={handleCloseModalCategory}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validationCategory.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalHeader toggle={handleCloseModalCategory}>Создание категории</ModalHeader>
                        <ModalBody>
                            <div className="mt-3">
                                <Label htmlFor="category_title" className="form-label">
                                    <h5>Категория</h5>
                                </Label>
                                <Col xxl={3} md={6} style={{ width: "100%" }}>
                                    <Input
                                        id="category_title"
                                        name="title"
                                        type="text"
                                        rows="3"
                                        className="form-control"
                                        placeholder="Введите название категории"
                                        onChange={validationCategory.handleChange}
                                        onBlur={validationCategory.handleBlur}
                                        value={validationCategory.values.title || ""}
                                        invalid={
                                            validationCategory.touched.title &&
                                            validationCategory.errors.title
                                                ? true
                                                : !!errors?.title
                                        }
                                    />
                                    {validationCategory.touched.title &&
                                      validationCategory.errors.title ? (
                                        <FormFeedback type="invalid">
                                          {validationCategory.errors.title}
                                        </FormFeedback>
                                      ) : errors && errors?.title ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.title}</div>
                                        </FormFeedback>
                                      ) : null
                                    }
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <Button type="submit" color="success">
                                Добавить
                            </Button>{" "}
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default Expenses;