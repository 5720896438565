import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import styles from "./CRM.module.css";
import {Link, useLocation} from "react-router-dom";

import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";

import DeleteModal from "../../Components/Common/DeleteModal";

// import WidgetsTask from "./WidgetsTask";

import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import {
  getProductsApi,
  deleteProductApi,
  createProductApi,
  updateProductApi,
  importProducts,
  getAccountsApi,
  updateFromKaspiApi,
  progressUpdateFromKaspiAPI,
  getBotIntegrationApi,
  getBotPricelistApi, getStoragesApi, updateOnStorage, getBotStatsApi
} from "../../helpers/backend_helper";
import ImportForm from "./ImportForm";
import BreadCrumb from "../../Components/Common/BreadCrumb";
// import ProductDropdown from "./Helpers/ProductDropdown";
// import SelectedProducts from "./Helpers/SelectedProducts";
import TagInput from "../../Components/Common/TagInput";
import {IconButton, InputAdornment, Switch} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import MassPriceChangeModal from "../Integration/BotIntegration/MassPriceChangeModal";
import Filter from "../Integration/BotIntegration/Filter";
import AddModal from "../../Components/Common/AddModal";
import {getLoggedinUser} from "../../helpers/api_helper";
import InputMui from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "react-select";

const Products = () => {
  const location = useLocation();
  const maxSize = 1048576; // 1 MB in byte

  const user = getLoggedinUser();

  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [contact, setContact] = useState([]);
  const [products, setProducts] = useState([]);
  const [extraArticlesCodes, setExtraArticlesCodes] = useState([]);
  const [fetchParams, setFetchParams] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [delayTimer, setDelayTimer] = useState(null);

  const [paginationOptions, setPaginationOptions] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageValidationError, setImageValidationError] = useState(null);
  const [accounts, setAccounts] = useState({});
  /*const [settings, setSettings] = useState({
    "username": "",
    "price_change_step": 2,
    "skip_merchant_id": "",
    "bot_enabled": false
  });*/
  const [integration, setIntegration] = useState({
    "allowed": false,
    "enabled": false,
    "external_token": '',
    "event_logs": [
      // { formatted_created: '10.02.2024 08:30:15', log_type: 'Вход', description: 'Пользователь вошел в систему' },
      // { formatted_created: '10.02.2024 09:15:42', log_type: 'Выход', description: 'Пользователь вышел из системы' },
    ],  // readOnly
  });
  const botIntegrationAllowedCallback = useCallback(() => integration.allowed, [integration]);

  const [botProducts, setBotProducts] = useState([]);
  const [metaData, setMetaData] = useState({
    "bot_enabled": false,
    "meta": {
      "active_date": "",
      "api_integration_enabled": false,
      "with_remainders": false
    },
    "page": 1,
    "num_pages": 1,
    "search": "",
    "f": "all",
    "f_name": "Все опубликованные",
    "f_count": 0,
    "amount_rows": 0,
    "pricelist_errors": [],
    "pricelist_errors_count": 0,
    "f_select_data": {}
  });
  const [storages, setStorages] = useState([]);

  // const [botFilters, setBotFilters] = useState("all");
  const [botFilters, setBotFilters] = useState(null);
  const [manualSelfCostConf, setManualSelfCostConf] = useState(false);

  // Permissions
  const hasPermissionsCallback = useCallback((permissions) => {
    return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access);
  }, [accounts]);
  const hasPermissions = (permissions) => {
    return accounts.results && accounts.current && permissions.includes(accounts.results[accounts.current].access);
  };
  const getAccessObjects = () => {
    return String(accounts?.results && accounts.current && accounts?.results[accounts.current]?.access_objects);
  };

  /*useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getBotSettingsApi();
        setSettings(response);
      } catch (error) {}
    };
    fetchSettings();
  }, []);*/

  const fetchPricelist = (botFilters) => {
    let data = {f:'all'};
    getBotStatsApi(data)
      .then((json) => {
        let {...metadata} = json;

        if (data) {
          setMetaData(metadata);
        }
        /*if (botFilters) {
          setBotProducts(data);
        }else{
          setBotProducts([]);
        }*/
      })
      .catch((error) => {})
      .finally(() => {});
    // if (botFilters) {
    //   data.f = botFilters;
    // }
    // getBotPricelistApi(data)
    //     .then((json) => {
    //       let {data, ...metadata} = json;
    //
    //       if (data) {
    //         setMetaData(metadata);
    //       }
    //       /*if (botFilters) {
    //         setBotProducts(data);
    //       }else{
    //         setBotProducts([]);
    //       }*/
    //     })
    //     .catch((error) => {})
    //     .finally(() => {});
  };

  // const handleBotFiltersChange = (event) => {
  //   setBotFilters(event.target.value);
  //   console.log(event.target.value);
  //   const selected_bot_filters = event.target.value;
  //   if (selected_bot_filters) {
  //     setFetchParams({ ...fetchParams, bot_filters: selected_bot_filters });
  //     delete fetchParams.page;
  //   } else {
  //     let updatedFilters = { ...fetchParams };
  //     delete updatedFilters.bot_filters;
  //     setFetchParams(updatedFilters);
  //   }
  //   fetchProducts({ ...fetchParams, bot_filters: selected_bot_filters });
  // }

  useEffect(() => {
    fetchPricelist(botFilters);
    setManualSelfCostConf(getLoggedinUser()?.manual_self_cost_conf || false);
  }, []);

  useEffect(() => {
    const fetchIntegration = () => {
      getBotIntegrationApi()
      .then((response) => {
        setIntegration(response);
      })
      .catch(() => {});
    };
    const fetchAccounts = () => {
      getAccountsApi()
      .then((data) => {
        setAccounts(data);
      })
      .catch(() => {});
    };
    const fetchStorages = () => {
      getStoragesApi()
          .then((data) => {
            setStorages(data);
          })
          .catch(() => {});
    };
    fetchAccounts();
    fetchIntegration();
    if (user?.manual_storages_conf) {
      fetchStorages();
    }
  }, []);

  const storagesFullCallback = useCallback(() => storages.length > 0, [storages]);

  const fetchProducts = ({page, search, productType, bot_filters}) => {
    let data = {};
    if (page) {
      data.page = page;
      setCurrentPage(page);
    }else{
      setCurrentPage(1);
    }
    if (search && search.length > 0) {
      data.search = search;
    }
    if (productType) {
      data.productType = productType;
    }
    if (bot_filters) {
      data.bot_filters = bot_filters;
    }
    getProductsApi(data)
      .then((data) => {
        data.results.reverse();
        setProducts(data.results);
        setExtraArticlesCodes(data.extra_articles);
        setFilteredProducts(data.results);
        setPaginationOptions({
          count: data.count,
          previous: data.previous,
          next: data.next
        });
      })
      .catch((error) => {
        toast.error("Не удалось получить данные товаров", {
          position: "top-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const message = location.state && location.state.message;
    if (message) {
      toast.success(message);
    }
    // fetchProducts({});
  }, []);

  //////////ПОИСК//////////////////
  const [searchTerm, setSearchTerm] = useState("");
  const [searchProducts, setSearchProducts] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleProductsChange = (tags) => {
    // setSearchProducts(tags.split(','));
    setSearchTerm(tags);
  };


  /////////////////////////

  useEffect(() => {
    if (searchTerm) {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
      const timer = setTimeout(() => {
        const updatedParams = { ...fetchParams, search: searchTerm, page: 1 }; // Reset page to 1
        setFetchParams(updatedParams);
        fetchProducts(updatedParams);
      }, 700); // Adjust delay time as needed
      setDelayTimer(timer);
    } else {
      const { search, page, ...updatedParams } = fetchParams;
      updatedParams.page = 1; // Reset page to 1
      setFetchParams(updatedParams);
      fetchProducts(updatedParams);
    }
  }, [searchTerm]);



  const onPageChange = (data) => {
    const updatedFetchParams = { ...fetchParams };
    if (data.page){
      updatedFetchParams.page = data.page;
    }else{
      delete updatedFetchParams.page;
    }
    // Call fetchProducts with an updated params
    fetchProducts(updatedFetchParams);
    setFetchParams(updatedFetchParams);
  };


  //delete Contact
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [createMulti, setCreateMulti] = useState(false);
  const [isCreateButton, setIsCreateButton] = useState(false);

  const [filtering, setFiltering] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    // Define an async function
    const fetchProgress = async () => {
      try {
        const data = await progressUpdateFromKaspiAPI();
        const { progress, is_updating } = data;
        setIsUpdating(is_updating);
        setLoading(is_updating);
      } catch (error) {
        console.log(error);
      }
    };
    // Call the async function
    fetchProgress();
  }, [isUpdating]); // Empty dependency array ensures this runs once on mount

  const startUpdateFromKaspi = () => {
    if (!isUpdating) {
      setIsUpdating(true);
      setLoading(true);
      setImporting(false);
      setFiltering(false);
      // Запускаем процесс на сервере
      updateFromKaspiApi()
          .then((data) => {
            fetchProducts(fetchParams);
            toast.success(data?.message, {position: 'top-right'});
            setIsUpdating(false);
            setLoading(false);
          })
          .catch((error) => {
            if (String(error?.response?.status).startsWith('4')) {
              const { message } = error.response.data;
              toast.error(message, { position: "top-right" })
            }else{
              toast.error('Не удалось обновить товары', { position: "top-right" })
            }
            setIsUpdating(false);
            setLoading(false);
          });
    }
  };

  // useEffect(() => {
  //   const checkUpdating = () => {
  //     // getAccountsApi()
  //     //     .then((data) => {
  //     //       setAccounts(data);
  //     //     })
  //     //     .catch(() => {
  //     //       toast.error("Не удалось получить список аккаунтов", {
  //     //         position: "top-right",
  //     //       });
  //     //     })
  //   }
  //   fetchAccounts();
  // }, []);

  const toggleFiltering = () => {
    if (filtering) {
      setFiltering(false);
    } else {
      setImporting(false);
      setFiltering(true);
    }
  };

  const toggleImporting = () => {
    if (importing) {
      setImporting(false);
    } else {
      setFiltering(false);
      setImporting(true);
    }
  };

  const toggleOnStorage = (product, storageName, storageId) => {
    let newStorageState = '';

    switch (product.storages[storageName]) {
      case 'YES':
        newStorageState = 'NO';
        break;
      case 'NO':
        newStorageState = 'YES';
        break;
      default:
        newStorageState = 'YES';
    }

    const requestData = {
      product_id: product.id,
      storage_id: storageId,
      on_storage: newStorageState
    };

    updateOnStorage(requestData)
      .then((response) => {
        updateItemInProducts({
          ...product,
          storages: {
            ...product.storages,
            [storageName]: response.on_storage
          }
        });
      })
      .catch((error) => {
        toast.error("Ошибка при обновлении статуса 'на складе'");
      });
  };

  const create = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      // dispatch(deleteTask(element.value));  // delete with checkboxes
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsCreateButton(false);
    checkall.checked = false;
  };

  const createbox = () => {
    const ele = document.querySelectorAll(".taskCheckBox:checked");
    ele.length > 0 ? setIsCreateButton(true) : setIsCreateButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const [modal, setModal] = useState(false);
  const [modalTask, setModalTask] = useState(false);
  const [errors, setErrors] = useState({});

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
      setSelectedFile(null);
      setSelectedImage("");
    } else {
      setErrors({});
      setImageValidationError(null);
      validation.resetForm();
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const toggleTask = useCallback(() => {
    if (modalTask) {
      setModalTask(false);
      setContact(null);
      setSelectedFile(null);
      setSelectedImage("");
    } else {
      setModalTask(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modalTask]);

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      deleteProductApi(contact)
        .then(() => {
          fetchProducts(fetchParams);
          // const updatedProducts = (prevProduct) => prevProduct.filter((item) => item.id !== contact.id);
          // setProducts(updatedProducts);
          // setFilteredProducts(updatedProducts);
          toast.success("Товар был успешно удалён.", { position: "top-right" });
        })
        .catch((error) => {
          // console.error(error);
          toast.error("Не удалось удалить товар.", { position: "top-right" });
        });
      setDeleteModal(false);
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    // Create Contact
    validation.resetForm();
    setErrors({});
    setContact("");
    setSelectedFile(null);
    setSelectedImage("");
    setIsEdit(false);
    setIsEdited(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };
  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  //createTask
  const [task, setTask] = useState([]);
  const valid = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      taskId: (task && task.taskId) || "",
      project: (task && task.project) || "",
      task: (task && task.task) || "",
      creater: (task && task.creater) || "",
      dueDate: (task && task.dueDate) || "",
      status: (task && task.status) || "New",
      priority: (task && task.priority) || "High",
      subItem: (task && task.subItem) || [],
    },
    taskSchema: Yup.object({
      taskId: Yup.string().required("Please Enter Task Id"),
      project: Yup.string().required("Please Enter Project Name"),
      task: Yup.string().required("Please Enter Your Task"),
      creater: Yup.string().required("Please Enter Creater Name"),
      // dueDate: Yup.string().required("Please Enter Due Date"),
      status: Yup.string().required("Please Enter Status"),
      priority: Yup.string().required("Please Enter Priority"),
      subItem: Yup.array().required("Please Enter"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTask = {
          _id: task ? task._id : 0,
          taskId: values.taskId,
          project: values.project,
          task: values.task,
          creater: values.creater,
          dueDate: date,
          status: values.status,
          priority: values.priority,
          subItem: values.subItem,
        };
        // update customer
        // dispatch(updateTask(updatedTask));  // UPDATE TASK
        valid.resetForm();
      } else {
        // const newTask = {
        //   _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        //   taskId: values["taskId"],
        //   project: values["project"],
        //   task: values["task"],
        //   creater: values["creater"],
        //   dueDate: date,
        //   status: values["status"],
        //   priority: values["priority"],
        //   subItem: values["subItem"],
        // };
        // save new customer
        // dispatch(addNewTask(newTask));  // ADD NEW TASK
        task.resetForm();
      }
      toggleTask();
    },
  });
  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };
  const [date, setDate] = useState(defaultdate());

  // Create a function to update an element in products
  const updateItemInProducts = (updatedItem) => {
    const updatedProducts = (prevProducts) => {
      return prevProducts.map((item) => {
        if (item.id === updatedItem.id) {
          // Update the item with the updatedItem data
          if (updatedItem.storages) {
            return updatedItem;
          }
          return { ...item, ...updatedItem, storages: item.storages };
        }
        return item;
      });
    };
    setProducts(updatedProducts);
    setFilteredProducts(updatedProducts);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      image: (contact && contact.image) || "",
      title: (contact && contact.title) || "",
      code: (contact && contact.code) || "",
      description: (contact && contact.description) || "",
      price: (contact && contact.price) || "",

      on_storage: (contact && contact.on_storage) || "AUTO",
      export: (contact && contact.export) || false,
      bot_enabled: (contact && contact.bot_enabled) || false,
      // description: (contact && contact.description) || "",
      min_price: (contact && contact.min_price) || "",
      max_price: (contact && contact.max_price) || "",
      preorder: (contact && contact.preorder) || "",
      kaspi_code: (contact && contact.kaspi_code) || "",
      no_competitor_price: (contact && contact.no_competitor_price) || "",
      tags: (contact && contact.tags) || [],

      kaspi_price_now: (contact && contact.kaspi_price_now) || "",
      top_price: (contact && contact.top_price) || "",
    },
    validationSchema: Yup.object({
      // image: Yup.string().required("Пожалуйста выберите картинку"),
      title: Yup.string().required("Пожалуйста введите название Товара"),
      code: Yup.string().required("Пожалуйста введите Артикул"),
      price: Yup.number().required("Пожалуйста выставите Цену продажи"),
      export: Yup.bool(),
    }),
    validateOnChange: true,

    validate: (values) => {
      const errors = {};
      if (values.price === 0) {
        values.price = "";
      } else if (values.price < 0) {
        errors.price = "Цена продажи не может быть отрицательной";
      } else if (values.price > 9999999) {
        errors.price = "Цена продажи не может быть больше 9999999";
      }

      if (values.min_price === 0) {
        values.min_price = "";
      } else if (values.min_price < 0) {
        errors.min_price = "Минимальная цена не может быть отрицательной";
      } else if (values.price > 9999999) {
        errors.min_price = "Минимальная цена не может быть больше 9999999";
      } else if (String(values.min_price).includes('.')) {
        errors.min_price = "Введите целое число";
      }

      if (values.max_price === 0) {
        values.max_price = "";
      } else if (values.max_price < 0) {
        errors.max_price = "Максимальная цена не может быть отрицательной";
      } else if (values.price > 9999999) {
        errors.max_price = "Максимальная цена не может быть больше 9999999";
      } else if (String(values.max_price).includes('.')) {
        errors.max_price = "Введите целое число";
      }

      if (values.no_competitor_price === 0) {
        values.no_competitor_price = "";
      } else if (values.no_competitor_price < 0) {
        errors.no_competitor_price = "Неконкурентная цена не может быть отрицательной";
      } else if (values.no_competitor_price > 9999999) {
        errors.no_competitor_price = "Неконкурентная цена не может быть больше 9999999";
      } else if (String(values.no_competitor_price).includes('.')) {
        errors.no_competitor_price = "Введите целое число";
      }

      if (values.preorder === 0) {
        values.preorder = "";
      } else if (values.preorder < 0) {
        errors.preorder = "Количество дней предзаказа не может быть отрицательным";
      } else if (values.preorder > 100) {
        errors.preorder = "Максимальное значение 100";
      } else if (String(values.preorder).includes('.')) {
        errors.preorder = "Введите целое число";
      }

      return errors;
    },

    onSubmit: (values) => {
      setErrors({});

      if (isEdit) {
        const productId = contact ? contact._id : 0;
        /*const updateProductData = {
            id: contact ? contact._id : 0,
            // image: values.image,
            title: values.title,
            code: values.code,
            description: values.description,
            price: values.price,
            // joined: dateFormat(),
            // time: timeFormat(),
            tags: assignTag,
        };*/
        const updateProductData = new FormData();
        if (selectedFile) {
          updateProductData.append("image", selectedFile);
        }
        updateProductData.append("title", values.title);
        updateProductData.append("code", values.code);
        updateProductData.append("description", values.description);
        updateProductData.append("price", values.price);
        // if (integration.allowed) {
        updateProductData.append("on_storage", values.on_storage);
        updateProductData.append("export", values.export);
        updateProductData.append("bot_enabled", values.bot_enabled);
        updateProductData.append("min_price", values.min_price);
        updateProductData.append("max_price", values.max_price);
        updateProductData.append("preorder", values.preorder);
        updateProductData.append("kaspi_code", values.kaspi_code);
        updateProductData.append("no_competitor_price", values.no_competitor_price);
        // updateProductData.append('tags', JSON.stringify([
        //   { "code": "118181750_582367089" },
        //   { "code": "110946034_495889" }
        // ]));
        let tagsData = [];
        values.tags.forEach(tag => {
          tagsData.push({ "code": tag });
        });
        updateProductData.append("tags", JSON.stringify(tagsData));

        updateProductApi(productId, updateProductData)
          .then((updatedProductData) => {
            // Call the function to update the element in products state
            updateItemInProducts(updatedProductData);
            // fetchProducts(fetchParams);
            setModal(false);
            toast.success("Товар успешно обновлён", { position: "top-right" });
          })
          .catch((error) => {
            if (
              error?.response?.data?.errors &&
              error?.response?.status === 400
            ) {
              setErrors(error.response.data.errors);
            } else {
              toast.error("Не удалось обновить товар", {
                position: "top-right",
              });
            }
          });
      } else {
        const newProductData = new FormData();
        if (selectedFile) {
          newProductData.append("image", selectedFile);
        }
        newProductData.append("title", values.title);
        newProductData.append("code", values.code);
        newProductData.append("description", values.description);
        newProductData.append("price", values.price);

        createProductApi(newProductData)
          .then((createdProductData) => {
            // Call the function to update the element in teamList
            let updatedProductsData = [createdProductData, ...products];
            setProducts(updatedProductsData);
            setModal(false);
            toast.success("Товар был создан успешно", {
              position: "top-right",
            });
          })
          .catch((error) => {
            // console.error(error);
            if (
              error?.response?.data?.errors &&
              error?.response?.status === 400
            ) {
              setErrors(error.response.data.errors);
            } else {
              // setErrors('Unexpected error occurred.')
              toast.error("Не удалось создать товар", {
                position: "top-right",
              });
            }
          });
      }
      if (!errors) {
        if (isEdit) {
          setIsEdit(false);
        }
        validation.resetForm();
        toggle();
      }
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact.id,
        image: contact.image,
        title: contact.title,
        code: contact.code,
        description: contact.description,
        // price: contact.price ? contact.price.split('.')[0]: '',
        price: contact.price,

        on_storage: contact.on_storage,
        export: contact.export,
        bot_enabled: contact.bot_enabled,
        min_price: contact.min_price,
        max_price: contact.max_price,
        preorder: contact.preorder,
        kaspi_code: contact.kaspi_code,
        no_competitor_price: contact.no_competitor_price,
        // joined: contact.joined,f
        // time: contact.time,
        // tags: contact.extra_articles_tags,
        tags: contact.extra_articles_tags,
      });
      // setAssignTag(contact.extra_articles_tags);
      // setTag(contact.extra_articles_tags);
      // TODO: if file exists -> set image url
      setSelectedFile(null);
      contact.image ?
            setSelectedImage(contact.image) :
          contact?.url_image ?
            setSelectedImage(contact?.url_image) :
          setSelectedImage("");

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Node API
  // useEffect(() => {
  //   if (isContactCreated) {
  //     setContact(null);
  //     dispatch(onGetContacts());
  //   }
  // }, [
  //   dispatch,
  //   isContactCreated,
  // ]);

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = async () => {
    const checkall = document.getElementById("checkBoxAll");

    for (const element of selectedCheckBoxDelete) {
      await deleteProductApi({ id: element.value })
        .then(() => {
          // setProducts((prevProduct) =>
          //   prevProduct.filter((item) => String(item.id) !== element.value)
          // );
        })
        .catch((error) => {
          toast.error("Не удалось удалить товар", { position: "top-right" });
        });
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    }
    fetchProducts(fetchParams);
    setDeleteModal(false);
    toast.success("Выбранные товары были успешно удалены", {
      position: "top-right",
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
    checkedAll();
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const handleImportProducts = async (formData) => {
    if ('import' in errors) {
      delete errors['import'];
    }
    try {
      setImportLoading(true);
      const response = await importProducts(formData);
      setImportLoading(false);
      if (response.success) {
        toast.success(response.message);
        fetchProducts({});
        setImporting(false);
      }else{
        // toast.error(response.message);
        setErrors({'import': response.message, ...errors});
      }
    } catch (error) {
      if (error?.response?.data && error?.response?.status === 400) {
        toast.error(error.response.data.message); // , { autoClose: 10000 }
      } else {
        toast.error("Возникла ошибка. Повторите попытку позже");
      }
    }
  };

  const updateProducts = async (rowIndex, columnId, value) => {
    // Локальное обновление данных
    setFilteredProducts((oldProducts) => {
      const newProducts = oldProducts.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      });

      // Получаем ID из обновлённого состояния
      const productId = newProducts[rowIndex]?.id;

      if (productId) {
        updateProductApi(productId, { [columnId]: value })
            .then(() => {
              toast.success("Данные успешно обновлены!");
            })
            .catch(() => {
              toast.error("Ошибка при обновлении данных.");
            });
      }

      return newProducts;
    });
  };

  const EditableCell = ({
      value: initialValue,
      row: { index },
      column: { id },
      updateProducts,
    }) => {
    const [value, setValue] = useState(initialValue);

    // Синхронизация локального состояния с initialValue
    useEffect(() => {
      setValue(initialValue || "");
    }, [initialValue]);

    const onChange = (e) => {
      const newValue = e.target.value;
      // Оставляем только цифры
      if (/^\d*$/.test(newValue)) {
        setValue(newValue);
      }
    };

    const onKeyDown = (e) => {
      // Запрещаем ввод любых символов, кроме цифр
      if (
          !/^\d$/.test(e.key) && // Разрешить цифры
          e.key !== "Backspace" && // Разрешить Backspace
          e.key !== "Delete" && // Разрешить Delete
          e.key !== "ArrowLeft" && // Разрешить стрелку влево
          e.key !== "ArrowRight" && // Разрешить стрелку вправо
          e.key !== "Tab" && // Разрешить Tab
          !(e.ctrlKey && (e.key === "c" || e.key === "x" || e.key === "v")) // Разрешить Ctrl + C и Ctrl + X
      ) {
        e.preventDefault();
      }
    };

    const onBlur = async (e) => {
      e.target.style.backgroundColor = "transparent";
      e.target.style.borderColor = "transparent";
      if (value !== initialValue) {
        await updateProducts(index, id, value ? parseInt(value, 10) : null);
      }
    };

    return (
        <input
            type="text"
            value={value}
            onKeyDown={onKeyDown} // Проверка ввода
            onChange={onChange} // Проверка значения
            onBlur={onBlur}
            style={{
              width: "90%",
              outline: "none",
              border: "1px solid transparent",
              backgroundColor: "transparent",
              padding: "5px",
              transition: "background-color 0.2s, border-color 0.2s",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#f5f5f5";
              e.target.style.borderColor = "#ddd";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.borderColor = "transparent";
            }}
            onFocus={(e) => {
              e.target.style.backgroundColor = "#e8f0fe";
              e.target.style.borderColor = "#a5d5ff";
            }}
        />
    );
  };

  // Customber Column
  const columns = useMemo(() =>
    {
      const baseColumns = [{
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Название",
        accessor: "title",
        filterable: true,
        Cell: (product) => (
          <>
            <div
              onClick={() => {
                const contactData = product.row.original;
                handleContactClick(contactData);
                setErrors({});
                validation.resetForm();
              }}
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                cursor: 'pointer',
                maxWidth: integration?.allowed ? '400px' : '800px'
              }}
            >
              <div
                onClick={()=>{
                  if (product.row.original?.url_item) {
                    window.open(product.row.original.url_item, '_blank');
                  }
                }}
                style={{
                  height: "30px",
                  width: "35px",
                  minHeight: "30px",
                  minWidth: "35px",
                  marginRight: (integration?.allowed && product.row.original.export) ? "0px" :"7px",
                  verticalAlign: "middle",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {product.row.original.image || product.row.original.url_image ? (
                  <img
                    src={product.row.original.image || product.row.original.url_image}
                    style={{
                      width: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    alt="картинка товара"
                  />
                ) : null}
              </div>
              {integration?.allowed && product.row.original.export &&
                  <CircleIcon
                      style={{
                        height: '14px',
                        // transform: 'translateY(15px)',
                        // color: product.row.original.export ? '#9def86' : '#ffc3c3'
                        color: '#9def86'
                      }}
                  />
              }
              <span
                  style={{
                    lineHeight: '1.3', wordWrap: 'break-word',
                    whiteSpace: 'normal', textDecoration: 'underline', color: '#33839f'
                  }}
              >
                {product.row.original.title}
              </span>

            </div>
          </>
        ),
      },
      {
        Header: "Артикул",
        accessor: "code",
        filterable: true,
        Cell: (product) => (
          <>
            {/*<Link to={ "/products/" + product.row.original.id + '/' }>*/}
            {/*    <span style={{color: '#5691b6'}}>*/}
            {product.row.original.code}
            {/*</span>*/}
            {/*</Link>*/}
          </>
        ),
      },
      {
        Header: "Доп. артикулы",
        filterable: false,
        Cell: (product) => {
          const extraArticles = product.row.original.extra_articles_tags || [];
          let displayedArticles = extraArticles
              .sort((a, b) => b.length - a.length)
              .slice(0, 4);

          if (extraArticles.length > 4) {
            displayedArticles = displayedArticles.concat(['...']);
          }
          return (
              <>
                {displayedArticles.map((item, key) => (
                    <React.Fragment key={key}>
                      <span className="badge bg-primary-subtle text-primary me-1">
                        &nbsp;{item}&nbsp;
                      </span>
                      {key % 2 ? (key !== 3 && <br />) : null}
                    </React.Fragment>
                ))}
              </>
          );
        },
      },
      // {
      //   Header: "На складе",
      //   accessor: "on_storage",
      //   filterable: true,
      //   Cell: (product) => (
      //       <>
      //         {product.row.original.code} {/* .on_storage */}
      //       </>
      //   ),
      // },
      {
        Header: "Цена продажи",
        accessor: "price",
        filterable: false,
        // Cell: (product) => (
        //   <>
        //     {/*<span>{product.row.original.price && product.row.original.price.split('.')[0] || "-"}</span>*/}
        //     <span>{product.row.original.price || "-"}</span>
        //   </>
        // ),
        Cell: (product) => (
            <EditableCell {...product} updateProducts={updateProducts}/>
        ),
      },
      // {
      //   Header: "Продано",
      //   accessor: "sold",
      //   filterable: false,
      //   Cell: (product) => (
      //     <>
      //       <span>{product.row.original.sold}</span>
      //     </>
      //   ),
      // },
      // {
      //   Header: "Дата создания",
      //
      //   Cell: (contact) => (
      //     <>
      //       <small className="text-muted">
      //         {contact.row.original.created}
      //       </small>
      //     </>
      //   ),
      // },
      {
        Header: "Действие",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">

              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">

                    <DropdownItem
                      className="dropdown-item edit-item-btn"
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        handleContactClick(contactData);
                        setErrors({});
                        validation.resetForm();
                      }}
                    >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Редактировать
                    </DropdownItem>
                    {hasPermissions(['owner']) && (
                      <DropdownItem
                        className="dropdown-item remove-item-btn"
                        href="#"
                        onClick={() => {
                          const contactData = cellProps.row.original;
                          onClickDelete(contactData);
                        }}
                      >
                        <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                        Удалить
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      }]
      if (hasPermissionsCallback(['owner', 'admin'])) {
        baseColumns.splice(5, 0, {
          Header: "Себестоимость",
          accessor: manualSelfCostConf ? "manual_self_cost" : "self_cost",
          filterable: false,
          Cell: (product) => !manualSelfCostConf ? (
            <>
              {/*<span>{product.row.original.self_cost && product.row.original.self_cost.split('.')[0] || "-"}</span>*/}
              <span>{product.row.original.self_cost || "-"}</span>
            </>
          ) : (
            <EditableCell {...product} updateProducts={updateProducts}/>
          ),
        })
      }
      if (storagesFullCallback && user?.manual_storages_conf) {
        let columns = storages.map(({ id, name }) => ({
          Header: name,
          accessor: `storages.${name}`,
          filterable: false,
          Cell: ({ row }) => {
            // Проверяем наличие данных для склада
            const storageValue = row.original.storages && row.original.storages[name];

            return (
                <button
                    style={{ textAlign: 'center' }}
                    className={`btn btn-sm ${
                        storageValue === 'YES'
                            ? 'btn-success'
                            : storageValue === 'NO'
                                ? 'btn-danger'
                                : 'btn-primary'
                    }`}
                    onClick={() => toggleOnStorage(row.original, name, id)}
                    disabled={!storageValue}
                >
                  {storageValue || 'N/A'}  {/* Если значения нет, показываем 'N/A' */}
                </button>
            );
          },
        }));

        // Добавление колонок для складов в baseColumns
        baseColumns.splice(5, 0, ...columns); // Вставляем колонки для складов на 3-й позиции
      }

      if (botIntegrationAllowedCallback()) {  // botIntegrationAllowedCallback()
        /*baseColumns.splice(3, 0,{
          Header: "На складе",
          accessor: "on_storage",
          filterable: true,
          Cell: (product) => (
              <button
                  style={{textAlign: 'center'}}
                  className={`btn btn-sm ${
                      product.row.original.on_storage === 'YES'
                          ? 'btn-success'
                          : product.row.original.on_storage === 'NO'
                              ? 'btn-danger'
                              : 'btn-primary'
                  }`}
                  onClick={() => toggleOnStorage(product.row.original)}
              >
                {product.row.original.on_storage}
              </button>
          ),
        })*/
        baseColumns.splice(5+storages.length, 0, {
          Header: "Мин. цена",
          accessor: "min_price",
          filterable: true,
          // Cell: (product) => (
          //     <>
          //       {product.row.original.min_price}
          //     </>
          // ),
          Cell: (product) => (
              <EditableCell {...product} updateProducts={updateProducts}/>
          ),
        },
        {
          Header: "Макс. цена",
          accessor: "max_price",
          filterable: true,
          // Cell: (product) => (
          //     <>
          //       {product.row.original.max_price}
          //     </>
          // ),
          Cell: (product) => (
              <EditableCell {...product} updateProducts={updateProducts}/>
          ),
        },
        {
          Header: "Неконкур. цена",
          accessor: "no_competitor_price",
          filterable: true,
          // Cell: (product) => (
          //     <>
          //       {product.row.original.no_competitor_price}
          //     </>
          // ),
          Cell: (product) => (
              <EditableCell {...product} updateProducts={updateProducts}/>
          ),
        },
        /*{
          Header: "Kaspi код",
          accessor: "kaspi_code",
          filterable: true,
          Cell: (product) => (
              <>
                {product.row.original.kaspi_code}
              </>
          ),
        },*/
        {
          Header: "Предзаказ",
          accessor: "preorder",
          filterable: true,
          // Cell: (product) => (
          // <>
          //   {product.row.original.prekey=er}
          // </>
          Cell: (product) => (
              <EditableCell {...product} updateProducts={updateProducts}/>
          ),
        })
      }
      return baseColumns;
    },
    [handleContactClick, checkedAll, hasPermissionsCallback, botIntegrationAllowedCallback, storagesFullCallback, manualSelfCostConf]
  );

  // Define your static options
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  function handlestag(tags) {
    // setTag(tags);
    const assigned = tags.map((item) => item.value);
    setAssignTag(assigned);
    // Объединяем обновленные теги с другими данными контакта
    setContact((prevContact) => ({
      ...prevContact,         // Сохраняем предыдущие данные
      tags: assigned,         // Обновляем только поле tags
    }));
  }

  // SideBar Contact Deatail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  // Mass Price Change
  const [isMassPriceChangeModalOpen, setMassPriceChangeModalOpen] = useState(false);
  const toggleMassPriceChangeModal = () => {
      setMassPriceChangeModalOpen(!isMassPriceChangeModalOpen);
  };
  const [updateFromKaspiModal, setUpdateFromKaspiModal] = useState(false);

  document.title = "CRMBEK : Товары";

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={[]}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteContact}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <AddModal
            show={updateFromKaspiModal}
            onAddClick={() => {
              startUpdateFromKaspi();
              setUpdateFromKaspiModal(false);
            }}
            onCloseClick={() => setUpdateFromKaspiModal(false)}
            text='Вы опубликовали все нужные товары на Каспи? Нажмите "Да" чтобы запустить обновление'
            actionText='Да'
        />
       
        <Container fluid>
          <BreadCrumb title="Товары" />

          {hasPermissions(['owner']) ||
          getAccessObjects().includes('products') ? (
          <Row className="pb-5"> {/*px-5  */}
            <Col lg={12}>
              <Card style={{ marginBottom: "0" }}>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1 d-flex align-items-center">
                      {/*<div>*/}
                      {/*  <h5 style={{ margin: "0 15px 0 0" }}>Товары</h5>*/}
                      {/*</div>*/}
                      <Link to="/add-product" disabled={isUpdating} style={isUpdating ? {pointerEvents: 'none'} : {}}>
                        <button
                            disabled={isUpdating}
                            // style={{ padding: "5px 25px" }}
                            className="btn btn-info add-btn"
                            onClick={() => {
                              setModal(true);
                              setIsEdit(false);
                              setContact(null);
                              setSelectedFile(null);
                              setSelectedImage("");
                              setErrors({});
                              validation.resetForm();
                            }}
                        >
                            <span style={{fontSize: "14px", fontWeight: "400"}}>
                              <i className="ri-add-fill me-1 align-bottom fs-14"></i>Товар
                            </span>
                        </button>
                      </Link>
                      <button
                          className={`btn ${isUpdating ? 'btn-primary' : 'btn-outline-primary'} fs-13 ${styles.filter_button}`}
                          // onClick={startUpdateFromKaspi}
                          onClick={() => setUpdateFromKaspiModal(true)}
                          disabled={isUpdating}
                      >
                        Обновить из Kaspi
                        {/*{" "}*/}
                        {/*<i className="ri-add-box-fill me-1 align-bottom"></i>*/}
                      </button>
                      <button
                          disabled={isUpdating}
                          className={`btn ${filtering ? 'btn-primary' : 'btn-outline-primary'} px-2 fs-14 ${styles.filter_button}`}
                          onClick={toggleFiltering}
                      >
                        Фильтры{" "}
                        <i className="ri-filter-2-line me-1 align-bottom"></i>
                      </button>
                    </div>

                    <div style={{ flexGrow: 1, position: 'relative' }}>
                      <InputMui
                          fullWidth={true}
                          placeholder="Поиск..."
                          value={searchTerm}
                          onChange={(e) => {
                            setFiltering(false);
                            handleProductsChange(e.target.value.split(','));
                          }}
                          sx={{
                            padding: '4px 8px',
                            borderRadius: '18px',
                            backgroundColor: '#f3f3f9',
                            outline: 'none',
                            border: 'none',
                            textDecoration: 'none',
                            '&::before': {
                              content: 'none', // Ensure that no content is being added
                            },
                            '&::after': {
                              content: 'none', // Ensure that no content is being added
                            },
                            // fontFamily: 'Rubik, sans-serif'
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          endAdornment={
                              searchTerm && (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => {
                                      setFiltering(false);
                                      handleProductsChange([]);
                                    }} edge="end">
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                              )
                          }
                      />
                    </div>

                    {/*{products && products.length > 0 && ()}*/}
                    <div className="d-flex flex-wrap gap-2">
                      {products && products.length > 0 && (
                        <>
                          {(!isUpdating && integration?.allowed) &&
                              <Button style={{lineHeight: 1}} color="info" onClick={toggleMassPriceChangeModal}>
                                Массовые изменения цен
                              </Button>
                          }
                          <MassPriceChangeModal
                          isOpen={isMassPriceChangeModalOpen}
                          toggle={toggleMassPriceChangeModal}
                          fetchProducts={fetchProducts}
                          fetchParams={fetchParams}
                          />
                        </>
                      )}

                      <button
                          disabled={isUpdating}
                          className={`btn btn-sm btn-primary px-3 fs-14`}
                          onClick={toggleImporting}
                      >
                        Импорт{" "}
                        {/*<i className="ri-filter-2-line me-1 align-bottom"></i>*/}
                      </button>
                      {/*<button
                          disabled={isUpdating}
                          style={{padding: "5px 15px"}}
                          className="btn btn-outline-primary add-btn me-1-bin-2-line"
                          onClick={() => {
                          }}
                      >
                        <img src="/printing.png" alt="printing"/>
                        <span style={{marginLeft: "6px"}}>Печать</span>
                      </button>*/}
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && hasPermissions(['owner']) && (
                            <button
                                style={{padding: "6px 15px"}}
                                className="btn btn-danger"
                                onClick={() => setDeleteModalMulti(true)}
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                      )}

                      {/*<button className="btn btn-primary">*/}
                      {/*  Фильтры{" "}*/}
                      {/*  <i className="ri-filter-2-line me-1 align-bottom"></i>*/}
                      {/*</button>*/}
                      </div>
                    </div>

                  </div>
                  {integration.allowed ?
                      <div className="mt-3">
                        <Filter
                            disabled={isUpdating}
                            withInitialFilter={true}
                            botFilters={botFilters}
                            metaData={metaData}
                            // handleChange={handleBotFiltersChange}
                            handleChange={(event) => {
                              setBotFilters(event.target.value);
                              const selected_bot_filters = event.target.value;
                              if (selected_bot_filters) {
                                setFetchParams({ ...fetchParams, bot_filters: selected_bot_filters });
                                delete fetchParams.page;
                              } else {
                                let updatedFilters = { ...fetchParams };
                                delete updatedFilters.bot_filters;
                                setFetchParams(updatedFilters);
                              }
                              fetchProducts({ ...fetchParams, bot_filters: selected_bot_filters });
                            }}
                        />
                      </div>
                      :
                      null
                  }
                  {metaData?.pricelist_errors?.length > 0 &&
                      <div style={{color: '#f46a6a', marginTop: "20px"}}>
                        <span className="fs-18">Ошибки ({metaData.pricelist_errors_count}):</span>
                        <ul className="list-messages mt-0">
                          {metaData.pricelist_errors.map(error => (
                              <li>{error}</li>
                          ))}
                        </ul>
                      </div>
                  }
                  {/*{!settings.bot_enabled &&
                      <ul className="list-messages mt-3 mb-0">
                        <li style={{color: '#f46a6a'}}>Бот отключен. Вы можете включить его обратно, нажав на кнопку "Включить бот"</li>
                      </ul>
                  }*/}
                </CardHeader>

              </Card>

              {filtering &&
                  <section className={styles.filter_section}>
                    <h2>Товары</h2>
                    <div className={styles.filter_element}>
                      <Label
                          htmlFor="product-field"
                          className={`form-label ${styles.filter_label}`}
                      >
                        Наименование
                      </Label>
                      {/*<Input*/}
                      {/*    type="text"*/}
                      {/*    placeholder="Поиск по товарам"*/}
                      {/*    value={searchTerm}*/}
                      {/*    onChange={handleSearchChange}*/}
                      {/*/>*/}
                      <TagInput
                          tags={searchTerm}
                          onTagsChange={(tags) =>
                              handleProductsChange(tags)
                          }
                      />
                      {/*<ProductDropdown
                          products={products}
                          productsLoading={productsLoading}
                          selectedProducts={selectedProducts}
                          onProductSelect={setSelectedProducts}
                      />*/}
                      {/*<SelectedProducts
                          selectedProducts={selectedProducts}
                          products={products}
                          onDelete={handleProductRemove}
                      />*/}
                    </div>
                    <div className={styles.filter_element}>
                      <Label
                          htmlFor="product-field"
                          className={`form-label ${styles.filter_label}`}
                      >
                        Группа товаров
                      </Label>
                      <select
                          // style={{height: '32px', padding: '6px'}}
                          value={filters.group || ""}
                          onChange={(e) => {
                            const selectedGroupId = e.target.value;
                            if (selectedGroupId) {
                              setFilters({...filters, group: selectedGroupId});
                            }else{
                              let updatedFilters = {...filters};
                              delete updatedFilters.group;
                              setFilters(updatedFilters);
                            }
                          }}
                          className={`form-select`}
                          aria-label="Default select example"
                      >
                        <option style={{ fontWeight: "bold" }} value="">
                          Группа
                        </option>
                        {/*{groups.map((group) => (*/}
                        {/*    <option key={group.id} value={group.id}>*/}
                        {/*      {group.name}*/}
                        {/*    </option>*/}
                        {/*))}*/}
                      </select>
                    </div>

                    {integration?.allowed &&
                      <div className={styles.filter_element}>
                        <Label
                            htmlFor="product-filter"
                            className={`form-label ${styles.filter_label}`}
                            // style={{color: 'transparent'}}
                        >
                          Тип товаров
                        </Label>
                        <select
                            value={fetchParams.productType || "all"}
                            onChange={(e) => {
                              const selectedType = e.target.value;
                              if (selectedType) {
                                setFetchParams({ ...fetchParams, productType: selectedType });
                                delete fetchParams.page;
                              } else {
                                let updatedFilters = { ...fetchParams };
                                delete updatedFilters.productType;
                                setFetchParams(updatedFilters);
                              }
                              fetchProducts({ ...fetchParams, productType: selectedType });
                            }}
                            className="form-select"
                            aria-label="Default select example"
                        >
                          <option value="all">Все товары</option>
                          <option value="exportable">Экспортируемые товары</option>
                          <option value="non_exportable">Неэкспортируемые товары</option>
                        </select>
                      </div>
                    }

                  </section>
              }

              {importing &&
                // importLoading ? (
                //   <div className={styles.loader}>
                //     <p>Loading...</p>
                //   </div>
                // ) :
                  (
                  <section className={styles.filter_section}>
                    <h2>Загрузить прайс-лист</h2>
                    <div className={`${styles.filter_element} ${styles.only}`}>
                      <Label
                          htmlFor="product-field"
                          className={`form-label ${styles.filter_label}`}
                      >
                        Прайс-лист
                      </Label>
                      <ImportForm
                          handleImportProducts={handleImportProducts}
                          errors={errors}
                          setErrors={setErrors}
                          disabled={importLoading}
                      />
                    </div>

                  </section>
                )
              }
            </Col>

            <Col xxl={9} style={{width: "100%"}}>
              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {
                      loading ? ( // Show loader only when loading
                          <Loader isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
                        ) :
                      products ? (
                        filteredProducts.length > 0 ? (
                            <TableContainer
                                columns={columns}
                                data={filteredProducts}
                                // isGlobalFilter={true}
                                // isAddUserList={true}
                                customPageSize={15}

                                serverCount={paginationOptions?.count}
                                serverNext={paginationOptions?.next}
                                serverPrevious={paginationOptions?.previous}
                                onPageChange={onPageChange}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}

                                className="custom-header-css"
                                divClass="table-responsive table-card mb-3"
                                tableClass={`align-middle table-nowrap ${styles.table_with_checkbox}`}
                                theadClass="table-light"
                                handleContactClick={handleContactClicks}
                                SearchPlaceholder="Поиск..."
                            />
                      ) : (
                        <h5
                          style={{ lineHeight: "1.6", color: "grey" }}
                          className="text-center mt-4"
                        >
                          Товаров не найдено.
                        </h5>
                      )
                    ) : (
                      <Loader isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
                      {/*{!!isEdit ? "Редактировать товар" : "Добавить товар"}*/}
                      {!!isEdit ? contact?.title : "Добавить товар"}
                    </ModalHeader>

                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          {/*<Col lg={12}>*/}
                          {/*    <div>*/}
                          {/*        <Label htmlFor="product-field" className="form-label">*/}
                          {/*            Товар*/}
                          {/*        </Label>*/}
                          {/*        <Select*/}
                          {/*            placeholder="Поиск..."*/}
                          {/*            name="product"*/}
                          {/*            id="product-field"*/}
                          {/*            options={options.slice(0, 5)}*/}
                          {/*            onChange={(selectedOption) => {*/}
                          {/*                validation.setFieldValue('product', selectedOption?.value);*/}
                          {/*            }}*/}
                          {/*            onBlur={validation.handleBlur('product')}*/}
                          {/*            value={options.find((option) => option.value === validation.values.product) || null}*/}
                          {/*            isClearable*/}
                          {/*            isSearchable*/}
                          {/*        />*/}
                          {/*        {validation.touched.product && validation.errors.product ? (*/}
                          {/*            <FormFeedback type="invalid">*/}
                          {/*                {validation.errors.product}*/}
                          {/*            </FormFeedback>*/}
                          {/*        ) : null}*/}
                          {/*    </div>*/}
                          {/*</Col>*/}
                          <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute  bottom-0 end-0">
                                  <Label
                                    htmlFor="customer-image-input"
                                    className="mb-0"
                                  >
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  <Input
                                    className="form-control d-none"
                                    id="customer-image-input"
                                    type="file"
                                    name="image"
                                    accept="image/png, image/jpeg, image/webp"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      if (file) {
                                        const fileSize = file.size; // Size in bytes

                                        if (fileSize > maxSize) {
                                          setImageValidationError('Размер фото превышает 1 MB')
                                        } else {
                                          setImageValidationError(null)
                                          setSelectedFile(file);
                                          const imageUrl = URL.createObjectURL(file);
                                          setSelectedImage(imageUrl);
                                        }

                                      } else {
                                        setSelectedFile(null);
                                        setSelectedImage("");
                                      }
                                      validation.handleChange(event);
                                    }}
                                    onBlur={validation.handleBlur}
                                    // value={validation.values.image || ""}
                                    value={""}
                                    invalid={
                                      validation.touched.image &&
                                      validation.errors.image
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light text-primary fs-12 lh-1">
                                    {" "}
                                    {/*rounded-circle */}
                                    {selectedImage ? (
                                      <img
                                        src={selectedImage}
                                        alt="картинка товара"
                                        id="customer-img"
                                        className="avatar-md object-fit-cover"
                                      /> /*rounded-circle*/
                                    ) : (
                                      "выберите картинку товара"
                                    )}
                                  </div>
                                  {imageValidationError && <p className="mt-2" style={{ color: '#ed5e5e', fontSize: '0.875em' }}>{imageValidationError}</p>}
                                </div>
                              </div>
                            </div>

                            <div className="mt-2">
                              <Label
                                htmlFor="title-field"
                                className="form-label mb-0"
                              >
                                Название Товара
                              </Label>

                              <Input
                                name="title"
                                id="title-field"
                                type="text"
                                className="form-control"
                                placeholder="Введите название..."
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                  validation.errors.title
                                    ? true
                                    : !!errors?.title
                                }
                              />
                              {validation.touched.title &&
                              validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : errors && errors?.title ? (
                                <FormFeedback type="invalid">
                                  <div>{errors.title}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12} className="mt-2">
                            <div>
                              <Label
                                htmlFor="code-field"
                                className="form-label mb-0"
                              >
                                Артикул
                              </Label>

                              <Input
                                name="code"
                                id="code-field"
                                type="text"
                                className="form-control"
                                placeholder="Введите артикул..."
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                  validation.touched.code &&
                                  validation.errors.code
                                    ? true
                                    : !!errors?.code
                                }
                              />
                              {validation.touched.code &&
                              validation.errors.code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.code}
                                </FormFeedback>
                              ) : errors && errors?.code ? (
                                <FormFeedback type="invalid">
                                  <div>{errors.code}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                  htmlFor="taginput-choices"
                                  className="form-label font-size-13 text-muted"
                              >
                                Доп. Артикулы
                              </Label>
                              <Select
                                  isMulti
                                  value={validation.values.tags?.map(code => ({
                                    value: code,
                                    label: code
                                  }))}  // TODO:1
                                  onChange={(e) => {
                                    handlestag(e);
                                  }}
                                  className="mb-0"
                                  invalid={
                                    true
                                    // validation.touched.description &&
                                    // validation.errors.description
                                    //     ? true
                                    //     : !!errors?.description
                                  }
                                  options={extraArticlesCodes?.map(code => ({
                                    value: code,
                                    label: code,
                                  }))}
                                  id="taginput-choices"
                              ></Select>

                              {validation.touched.tags &&
                              validation.errors.tags ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.tags}
                                  </FormFeedback>
                              ) : errors.tags ? (
                                  <div type="invalid" style={{color: '#ed5e5e', fontSize: '12px', lineHeight: '1.2', padding: '5px'}}>
                                    {errors.tags}
                                  </div>
                              ) : null}
                            </div>
                          </Col>
                          {!integration.allowed &&
                            <Col lg={12} className="mt-2">
                              <div>
                                <Label
                                    htmlFor="description-field"
                                    className="form-label mb-0"
                                >
                                  Описание
                                </Label>

                                <Input
                                    name="description"
                                    id="description-field"
                                    type="textarea"
                                    className="form-control"
                                    placeholder="Введите описание для товара..."
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                    invalid={
                                      validation.touched.description &&
                                      validation.errors.description
                                          ? true
                                          : !!errors?.description
                                    }
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.description}
                                    </FormFeedback>
                                ) : errors && errors?.description ? (
                                    <FormFeedback type="invalid">
                                      <div>{errors.description}</div>
                                    </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          }
                          {/*<Col lg={user?.manual_storages_conf ? 9 : 12} className="mt-2">*/}
                          <Col lg={12} className="mt-2">
                            <div>
                              <Label
                                htmlFor="price-field"
                                className="form-label mb-0"
                              >
                                Цена продажи
                              </Label>

                              <Input
                                name="price"
                                id="price-field"
                                type="number"
                                className="form-control"
                                placeholder="Введите стоимость..."
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.price || ""}
                                invalid={
                                  validation.touched.price &&
                                  validation.errors.price
                                    ? true
                                    : !!errors?.price
                                }
                              />
                              {validation.touched.price &&
                              validation.errors.price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : errors && errors?.price ? (
                                <FormFeedback type="invalid">
                                  <div>{errors.price}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {/*{user?.manual_storages_conf &&*/}
                          {/*  <Col lg={3} className="mt-2">*/}
                          {/*    <div>*/}
                          {/*      <Label htmlFor="on-storage-field"*/}
                          {/*             className="form-label d-block mb-0">*/}
                          {/*        На складе*/}
                          {/*      </Label>*/}

                          {/*      /!*<div className="d-flex justify-content-center">*!/*/}
                          {/*      <button*/}
                          {/*          type="button"*/}
                          {/*          className={`btn ${*/}
                          {/*              validation.values.on_storage === 'YES'*/}
                          {/*                  ? 'btn-success'*/}
                          {/*                  : validation.values.on_storage === 'NO'*/}
                          {/*                      ? 'btn-danger'*/}
                          {/*                      : 'btn-primary'*/}
                          {/*          }`}*/}
                          {/*          onClick={() => {*/}
                          {/*            let newStorageState = '';*/}

                          {/*            switch (validation.values.on_storage) {*/}
                          {/*              case 'YES':*/}
                          {/*                // newStorageState = 'AUTO';*/}
                          {/*                newStorageState = 'NO';*/}
                          {/*                break;*/}
                          {/*              // case 'AUTO':*/}
                          {/*              //   newStorageState = 'NO';*/}
                          {/*              //   break;*/}
                          {/*              case 'NO':*/}
                          {/*                newStorageState = 'YES';*/}
                          {/*                break;*/}
                          {/*              default:*/}
                          {/*                // newStorageState = 'AUTO';*/}
                          {/*                newStorageState = 'YES';*/}
                          {/*            }*/}

                          {/*            validation.setFieldValue("on_storage", newStorageState);*/}
                          {/*          }}*/}
                          {/*      >*/}
                          {/*        /!*{validation.values.on_storage || 'AUTO'}*!/*/}
                          {/*        {validation.values.on_storage || 'NO'}*/}
                          {/*      </button>*/}
                          {/*      /!*</div>*!/*/}

                          {/*      {validation.touched.on_storage && validation.errors.on_storage ? (*/}
                          {/*          <FormFeedback type="invalid">*/}
                          {/*            {validation.errors.on_storage}*/}
                          {/*          </FormFeedback>*/}
                          {/*      ) : null}*/}
                          {/*    </div>*/}
                          {/*  </Col>*/}
                          {/*}*/}


                          {integration?.allowed &&
                              <>
                                <Col lg={12} className="mt-2">
                                  <div>
                                    <Label
                                        htmlFor="min_price-field"
                                        className="form-label mb-0"
                                    >
                                      Минимальная цена
                                    </Label>

                                    <Input
                                        name="min_price"
                                        id="min_price-field"
                                        type="number"
                                        className="form-control"
                                        placeholder="Введите минимальную цену..."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.min_price || ""}
                                        invalid={
                                          validation.touched.min_price &&
                                          validation.errors.min_price
                                              ? true
                                              : !!errors?.min_price
                                        }
                                    />
                                    {validation.touched.min_price &&
                                    validation.errors.min_price ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.min_price}
                                        </FormFeedback>
                                    ) : errors && errors?.min_price ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.min_price}</div>
                                        </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                  <div>
                                    <Label
                                        htmlFor="max_price-field"
                                        className="form-label mb-0"
                                    >
                                      Максимальная цена
                                    </Label>

                                    <Input
                                        name="max_price"
                                        id="max_price-field"
                                        type="number"
                                        className="form-control"
                                        placeholder="Введите максимальную цену..."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.max_price || ""}
                                        invalid={
                                          validation.touched.max_price &&
                                          validation.errors.max_price
                                              ? true
                                              : !!errors?.max_price
                                        }
                                    />
                                    {validation.touched.max_price &&
                                    validation.errors.max_price ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.max_price}
                                        </FormFeedback>
                                    ) : errors && errors?.max_price ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.max_price}</div>
                                        </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                  <div>
                                    <Label
                                        htmlFor="kaspi_code-field"
                                        className="form-label mb-0"
                                    >
                                      Kaspi код
                                    </Label>

                                    <Input
                                        name="kaspi_code"
                                        id="kaspi_code-field"
                                        type="number"
                                        className="form-control"
                                        placeholder="Введите Kaspi код..."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.kaspi_code || ""}
                                        invalid={
                                          validation.touched.kaspi_code &&
                                          validation.errors.kaspi_code
                                              ? true
                                              : !!errors?.kaspi_code
                                        }
                                    />
                                    {validation.touched.kaspi_code &&
                                    validation.errors.kaspi_code ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.kaspi_code}
                                        </FormFeedback>
                                    ) : errors && errors?.kaspi_code ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.kaspi_code}</div>
                                        </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                  <div>
                                    <Label
                                        htmlFor="no_competitor_price-field"
                                        className="form-label mb-0"
                                    >
                                      Неконкурентная цена
                                    </Label>

                                    <Input
                                        name="no_competitor_price"
                                        id="no_competitor_price-field"
                                        type="number"
                                        className="form-control"
                                        placeholder="Введите вашу цену когда нет конкурентов..."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.no_competitor_price || ""}
                                        invalid={
                                          validation.touched.no_competitor_price &&
                                          validation.errors.no_competitor_price
                                              ? true
                                              : !!errors?.no_competitor_price
                                        }
                                    />
                                    {validation.touched.no_competitor_price &&
                                    validation.errors.no_competitor_price ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.no_competitor_price}
                                        </FormFeedback>
                                    ) : errors && errors?.no_competitor_price ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.no_competitor_price}</div>
                                        </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={9} className="mt-2">
                                  <div>
                                    <Label
                                        htmlFor="preorder-field"
                                        className="form-label mb-0"
                                    >
                                      Предзаказ дней
                                    </Label>

                                    <Input
                                        // style={{border: 0}}
                                        name="preorder"
                                        id="preorder-field"
                                        type="number"
                                        className="form-control"
                                        placeholder="Введите количество дней предзаказа..."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.preorder || ""}
                                        invalid={
                                          validation.touched.preorder &&
                                          validation.errors.preorder
                                              ? true
                                              : !!errors?.preorder
                                        }
                                    />
                                    {validation.touched.preorder &&
                                    validation.errors.preorder ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.preorder}
                                        </FormFeedback>
                                    ) : errors && errors?.preorder ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.preorder}</div>
                                        </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3} className="mt-2">
                                  <div className="">
                                    <Label
                                        htmlFor="bot_enabled-field"
                                        className="form-label d-block mb-0"
                                    >
                                      Бот включен
                                    </Label>
                                    <Input
                                        type="checkbox"
                                        name="bot_enabled"
                                        id="bot_enabled-field"
                                        className="form-control"
                                        style={{width: '25px', height: '25px', padding: 0}}
                                        checked={validation.values.bot_enabled || false}
                                        onChange={(e) => {
                                          validation.handleChange({
                                            target: {
                                              name: 'bot_enabled',
                                              value: e.target.checked,
                                            },
                                          });
                                        }}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          validation.touched.bot_enabled && validation.errors.bot_enabled
                                              ? true
                                              : !!errors?.bot_enabled
                                        }
                                    />
                                    {validation.touched.bot_enabled && validation.errors.bot_enabled ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.bot_enabled}
                                        </FormFeedback>
                                    ) : errors && errors?.bot_enabled ? (
                                        <FormFeedback type="invalid">
                                          <div>{errors.bot_enabled}</div>
                                        </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </>
                          }
                          {/*<Col lg={12}>*/}
                          {/*  <div>*/}
                          {/*    <Label*/}
                          {/*      htmlFor="taginput-choices"*/}
                          {/*      className="form-label font-size-13 text-muted"*/}
                          {/*    >*/}
                          {/*      Tags*/}
                          {/*    </Label>*/}
                          {/*    <Select*/}
                          {/*      isMulti*/}
                          {/*      value={tag}*/}
                          {/*      onChange={(e) => {*/}
                          {/*        handlestag(e);*/}
                          {/*      }}*/}
                          {/*      className="mb-0"*/}
                          {/*      options={tags}*/}
                          {/*      id="taginput-choices"*/}
                          {/*    ></Select>*/}

                          {/*    {validation.touched.tags &&*/}
                          {/*    validation.errors.tags ? (*/}
                          {/*      <FormFeedback type="invalid">*/}
                          {/*        {validation.errors.tags}*/}
                          {/*      </FormFeedback>*/}
                          {/*    ) : null}*/}
                          {/*  </div>*/}
                          {/*</Col>*/}
                        </Row>
                      </ModalBody>
                      {integration?.allowed ? (
                        <ModalFooter className="hstack gap-2 justify-content-between">
                          <div className="d-flex gap-2 align-items-baseline">
                            <Label
                                htmlFor="export-field"
                                className="form-label"
                            >
                              Экспортировать
                            </Label>
                            <Switch
                                name="export"
                                id="export-field"
                                className="form-control"
                                checked={validation.values.export || false}
                                onChange={(e) => {
                                  validation.handleChange({
                                    target: {
                                      name: 'export',
                                      value: e.target.checked,
                                    },
                                  });
                                }}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.export && validation.errors.export
                                      ? true
                                      : !!errors?.export
                                }
                            />
                            {validation.touched.export && validation.errors.export ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.export}
                                </FormFeedback>
                            ) : errors && errors?.export ? (
                                <FormFeedback type="invalid">
                                  <div>{errors.export}</div>
                                </FormFeedback>
                            ) : null}
                          </div>

                          <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                  setModal(false);
                                }}
                            >
                              {" "}
                              Закрыть{" "}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                id="add-btn"
                            >
                                {" "}
                                {!!isEdit ? "Сохранить" : "Создать"}{" "}
                            </button>
                          </div>
                        </ModalFooter>
                      ) : (
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                  setModal(false);
                                }}
                            >
                              {" "}
                              Закрыть{" "}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success"
                                id="add-btn"
                            >
                              {" "}
                              {!!isEdit ? "Сохранить" : "Создать"}{" "}
                            </button>
                          </div>
                        </ModalFooter>
                      )}
                    </Form>
                  </Modal>
                  {/* task modal */}
                  <Modal
                    isOpen={modalTask}
                    toggle={toggleTask}
                    centered
                    size="lg"
                    className="border-0"
                    modalClassName="modal fade zoomIn"
                  >
                    <ModalHeader
                      className="p-3 bg-info-subtle"
                      toggle={toggleTask}
                    >
                      {!!isEdited ? "Edit Task" : "Create Task"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        valid.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody className="modal-body">
                        <Row className="g-3">
                          <Col lg={12}>
                            <Label for="taskId-field" className="form-label">
                              Order Id
                            </Label>
                            <Input
                              name="taskId"
                              id="taskId-field"
                              className="form-control"
                              placeholder="Enter Task Id "
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.taskId || ""}
                              invalid={
                                valid.touched.taskId && valid.errors.taskId
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.taskId && valid.errors.taskId ? (
                              <FormFeedback type="invalid">
                                {valid.errors.taskId}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={12}>
                            <Label
                              for="projectName-field"
                              className="form-label"
                            >
                              Project Name
                            </Label>
                            <Input
                              name="project"
                              id="projectName-field"
                              className="form-control"
                              placeholder="Project name"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.project || ""}
                              invalid={
                                valid.touched.project && valid.errors.project
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.project && valid.errors.project ? (
                              <FormFeedback type="invalid">
                                {valid.errors.project}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                for="tasksTitle-field"
                                className="form-label"
                              >
                                Title
                              </Label>
                              <Input
                                name="task"
                                id="tasksTitle-field"
                                className="form-control"
                                placeholder="Title"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={valid.handleChange}
                                onBlur={valid.handleBlur}
                                value={valid.values.task || ""}
                                invalid={
                                  valid.touched.task && valid.errors.task
                                    ? true
                                    : false
                                }
                              />
                              {valid.touched.task && valid.errors.task ? (
                                <FormFeedback type="invalid">
                                  {valid.errors.task}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Label
                              for="clientName-field"
                              className="form-label"
                            >
                              Client Name
                            </Label>
                            <Input
                              name="creater"
                              id="clientName-field"
                              className="form-control"
                              placeholder="Client name"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.creater || ""}
                              invalid={
                                valid.touched.creater && valid.errors.creater
                                  ? true
                                  : false
                              }
                            />
                            {valid.touched.creater && valid.errors.creater ? (
                              <FormFeedback type="invalid">
                                {valid.errors.creater}
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            <Label for="duedate-field" className="form-label">
                              Due Date
                            </Label>

                            <Flatpickr
                              name="dueDate"
                              id="duedate-field"
                              className="form-control"
                              placeholder="Select a date"
                              options={{
                                altInput: true,
                                altFormat: "d M, Y",
                                dateFormat: "d M, Y",
                              }}
                              onChange={(e) => dateformate(e)}
                              value={valid.values.dueDate || ""}
                            />
                            {valid.touched.dueDate && valid.errors.dueDate ? (
                              <FormFeedback type="invalid">
                                {valid.errors.dueDate}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <Label for="ticket-status" className="form-label">
                              Status
                            </Label>
                            <Input
                              name="status"
                              type="select"
                              className="form-select"
                              id="ticket-field"
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.status || ""}
                            >
                              <option value="">Status</option>
                              <option value="New">New</option>
                              <option value="Inprogress">Inprogress</option>
                              <option value="Pending">Pending</option>
                              <option value="Completed">Completed</option>
                            </Input>
                            {valid.touched.status && valid.errors.status ? (
                              <FormFeedback type="invalid">
                                {valid.errors.status}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col lg={12}>
                            <Label for="priority-field" className="form-label">
                              Priority
                            </Label>
                            <Input
                              name="priority"
                              type="select"
                              className="form-select"
                              id="priority-field"
                              onChange={valid.handleChange}
                              onBlur={valid.handleBlur}
                              value={valid.values.priority || ""}
                            >
                              <option value="">Priority</option>
                              <option value="High">High</option>
                              <option value="Medium">Medium</option>
                              <option value="Low">Low</option>
                            </Input>
                            {valid.touched.priority && valid.errors.priority ? (
                              <FormFeedback type="invalid">
                                {valid.errors.priority}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </ModalBody>
                      <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            type="button"
                            onClick={() => {
                              setModalTask(false);
                            }}
                            className="btn-light"
                          >
                            Закрыть
                          </Button>
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            {!!isEdit ? "Update Task" : "Add Task"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Modal>
                </CardBody>
              </Card>
            </Col>

            {/* <Col xxl={3}>
              <Card id="contact-view-detail">
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <img
                      src={process.env.REACT_APP_API_URL + "/images/users/" + (info.image_src || "avatar-10.jpg")}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <span className="contact-active position-absolute rounded-circle bg-success">
                      <span className="visually-hidden"></span>
                    </span>
                  </div>
                  <h5 className="mt-4 mb-1">{info.name || "Tonya Noble"}</h5>
                  <p className="text-muted">{info.company || "Nesta Technologies"}</p>

                  <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-success-subtle text-success fs-15 rounded"
                      >
                        <i className="ri-phone-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-danger-subtle text-danger fs-15 rounded"
                      >
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-warning-subtle text-warning fs-15 rounded"
                      >
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul>
                </CardBody>
                <CardBody>
                  <h6 className="text-muted text-uppercase fw-semibold mb-3">
                    Personal Information
                  </h6>
                  <p className="text-muted mb-4">
                    Hello, I'm {info.name || "Tonya Noble"}, The most effective objective is one
                    that is tailored to the job you are applying for. It states
                    what kind of career you are seeking, and what skills and
                    experiences.
                  </p>
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">
                            Designation
                          </td>
                          <td>Lead Designer / Developer</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Email ID
                          </td>
                          <td>{info.email || "tonyanoble@velzon.com"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Phone No
                          </td>
                          <td>{info.phone || "414-453-5725"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Lead Score
                          </td>
                          <td>{info.lead_score || "154"}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Tags
                          </td>
                          <td>
                            {(info.tags ||
                              [
                                "Lead",
                                "Partner"
                              ]
                            ).map((item, key) => (<span className="badge bg-primary-subtle text-primary me-1" key={key}>{item}</span>))
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Last Contacted
                          </td>
                          <td>
                            {handleValidDate(info.last_contacted || "2021-04-13T18:30:00.000Z")}{" "}
                            <small className="text-muted">{handleValidTime(info.last_contacted || "2021-04-13T18:30:00.000Z")}</small>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          ) : (
              <h3 className="text-center my-5" style={{opacity: 0.3}}>У вас нет прав для просмотра данной страницы</h3>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Products;
