import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Loader from "../../Components/Common/Loader";
import styles from "../Invoices/InvoiceCreate.module.css";
import DetailsOrder from "./OrderDetails";
import {padNumberWithZeros} from "../../helpers/utils";

const OrderDetailsModal = ({isOpen, toggle, isReturns, orderNumber, orderId}) => {
    // const filteredProducts = products.filter(
    //     (product) =>
    //         product.title.toLowerCase().includes(searchValue.toLowerCase()) ||
    //         product.code.toLowerCase().includes(searchValue.toLowerCase())
    // );
    return (
        <Modal
            className={styles.modal_order}
            isOpen={isOpen}
            toggle={toggle}
            // style={{ "--vz-modal-width": "unset" }}
        >
            <ModalHeader toggle={toggle}>
                {/*Заказ #82571*/}
                <h4 style={{ paddingRight: "15px", marginBottom: 0 }}>
                    Редактирование
                    {isReturns ? ' возврата': ' заказа'}{/* #*/}
                    {/*{orderData?.number*/}
                    {/*{2
                        // ? padNumberWithZeros(orderData.number, 3)
                        ? padNumberWithZeros(2, 3)
                        : "000"
                    }*/}
                </h4>
            </ModalHeader>
            <ModalBody>
                {/*<div className={styles.header_modal} style={{ marginBottom: "15px" }}>
                    <Button
                        // onClick={onAddProduct}
                        color="success"
                        className="add-btn"
                        id="create-btn"
                    >
                        <i className="ri-add-line align-bottom me-1"></i> Создать товар
                    </Button>
                    <div className={styles.search_prod}>
                        <i className={`ri-search-line ${styles["add_prod"]}`}></i>
                        <input
                            style={{ border: "none" }}
                            placeholder="Поиск по товарам..."
                            type="text"
                            // onChange={onSearchChange}
                            // value={searchValue}
                        />
                    </div>
                </div>*/}
                <div>
                    <div style={{ overflowY: "auto", height: "85vh" }}>
                        {/*<h5 className="mt-5 mb-3 text-center">Ничего не найдено</h5>*/}
                        <DetailsOrder orderId={orderId} isReturns={isReturns} toggle={toggle}/>
                        {/*{productsLoading ? (*/}
                        {/*    <Loader />*/}
                        {/*) : filteredProducts.length > 0 ? (*/}
                        {/*    <table style={{ width: "100%" }}>*/}
                        {/*        <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th>Название</th>*/}
                        {/*            <th>Артикул</th>*/}
                        {/*            <th>Фото</th>*/}
                        {/*        </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody className={styles.table_modal_product}>*/}
                        {/*        {filteredProducts.map((product) => (*/}
                        {/*            <tr*/}
                        {/*                className={styles.table_modal_tr}*/}
                        {/*                key={product.id}*/}
                        {/*            >*/}
                        {/*                <th*/}
                        {/*                    style={{*/}
                        {/*                        cursor: "pointer",*/}
                        {/*                        color: "#0066ff",*/}
                        {/*                        wordBreak: "break-word",*/}
                        {/*                        minWidth: "150px",*/}
                        {/*                    }}*/}
                        {/*                    onClick={() => onSelectProduct(product)}*/}
                        {/*                >*/}
                        {/*                    {product.title}*/}
                        {/*                </th>*/}
                        {/*                <th*/}
                        {/*                    style={{*/}
                        {/*                        wordBreak: "break-word",*/}
                        {/*                        minWidth: "100px",*/}
                        {/*                    }}*/}
                        {/*                >*/}
                        {/*                    {product.code}*/}
                        {/*                </th>*/}
                        {/*                <th>*/}
                        {/*                    {product.image || product?.url_image ? (*/}
                        {/*                        <div*/}
                        {/*                            style={{*/}
                        {/*                                height: "30px",*/}
                        {/*                                textAlign: "center",*/}
                        {/*                                width: "35px",*/}
                        {/*                            }}*/}
                        {/*                        >*/}
                        {/*                            <img*/}
                        {/*                                src={product.image || product?.url_image}*/}
                        {/*                                alt=" "*/}
                        {/*                                style={{*/}
                        {/*                                    width: "auto",*/}
                        {/*                                    maxWidth: "100%",*/}
                        {/*                                    maxHeight: "100%",*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </div>*/}
                        {/*                    ) : (*/}
                        {/*                        <span>-</span>*/}
                        {/*                    )}*/}
                        {/*                </th>*/}
                        {/*            </tr>*/}
                        {/*        ))}*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*) : (*/}
                        {/*    <h5 className="mt-5 mb-3 text-center">Ничего не найдено</h5>*/}
                        {/*)}*/}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default OrderDetailsModal;