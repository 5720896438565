import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import styles from "../Expenses/Expenses.module.css";
import Loader from "../../../Components/Common/Loader";

const ProfitTable = ({ filteredProfitData, profitData, loading }) => {
    const data = useMemo(() => filteredProfitData || [], [filteredProfitData]);

    const columns = useMemo(
        () => [
            {
                Header: "Товар",
                accessor: "title",
                Cell: ({ row }) => (
                    <div className="d-flex align-items-center gap-3">
                        {row.original.image && (
                            <div style={{ width: "45px", height: "45px" }}>
                                <img
                                    src={row.original.image}
                                    alt="товар"
                                    style={{ maxHeight: "100%", maxWidth: "100%", margin: "0 auto" }}
                                />
                            </div>
                        )}
                        <div style={{ width: "77%", minWidth: "180px" }}>
                            <span style={{ wordBreak: "break-word" }}>{row.original.title}</span>
                        </div>
                    </div>
                ),
            },
            { Header: "Артикул", accessor: "code" },
            { Header: "кол-во продаж", accessor: "total_quantity" },
            { Header: "себест. шт.", accessor: "cost" },
            { Header: "цена продажи", accessor: "average_price" },
            { Header: "доставка сумма", accessor: "delivery_tax" },
            { Header: "комиссия сумма", accessor: "total_commission" },
            { Header: "налог 3%", accessor: "tax" },
            { Header: "сумма продаж", accessor: "total_revenue" },
            { Header: "сумма себес.", accessor: "total_cost" },
            {
                Header: "прибыль",
                accessor: "total_profit",
                Cell: ({ value }) => <strong>{value}</strong>,
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        { columns, data },
        useSortBy
    );

    return filteredProfitData ? (
        <div className="listjs-table" id="customerList">
            <div
                className="table-responsive table-card mt-1"
                style={{ maxHeight: "600px", overflowY: "auto", position: "relative" }}
            >
                <table
                    {...getTableProps()}
                    style={{ width: "100%", backgroundColor: "white" }}
                    className={styles.table_profit}
                >
                    <thead className={styles.title_thead}>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())} // Добавление сортировки
                                    style={{ cursor: 'pointer' }} // Делаем заголовок кликабельным
                                >
                                    {column.render("Header")}
                                    <span>
                      {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className={styles.scroll}>
                    {loading ? (
                        <tr>
                            <td colSpan={columns.length}>
                                <Loader />
                            </td>
                        </tr>
                    ) : (
                        rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    ))}
                                </tr>
                            );
                        })
                    )}
                    </tbody>
                    <tfoot className={styles.tfoot}>
                    <tr>
                        <td>
                            <span style={{ fontWeight: "bold", fontSize: "14px" }}>ИТОГО</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                <span style={{ fontWeight: "bold" }}>
                  {profitData?.totals?.total_revenue || 0}
                </span>
                        </td>
                        <td>
                <span style={{ fontWeight: "bold" }}>
                  {profitData?.totals?.total_cost || 0}
                </span>
                        </td>
                        <td>
                <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                  {profitData?.totals?.total_profit || 0}
                </span>
                        </td>
                        <td></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    // Show loader only when loading
    ) : loading ? (
        <Loader/>
    ) : (
        <h4 className="text-center mb-5 mt-5" style={{ opacity: 0.5 }}>
            Данных о прибыли не найдено
        </h4>
    )
};

export default ProfitTable;
