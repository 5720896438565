import React from "react";
import { Navigate } from "react-router-dom";


import Expenses from "../pages/Pages/Expenses";
import DetailsReception from "../pages/Invoices/DetailsReception";

//Crm Pages
import CrmContacts from "../pages/Crm/CrmContacts";

//Ecommerce
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import AddProduct from "../pages/Ecommerce/EcommerceProducts/AddProduct";
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct";
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
// import EcommerceOrderDetail from "../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail";
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
// import EcommerceSellers from "../pages/Ecommerce/EcommerceSellers/index";
// import EcommerceSellerDetail from "../pages/Ecommerce/EcommerceSellers/EcommerceSellerDetail";


import Settings from '../pages/Pages/Profile/Settings/Settings';
import Team from '../pages/Pages/Team/Team';

import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';


//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

import Reception from "../pages/Invoices/Reception";
import Returns from "../pages/Returns";
import EmailVerify from "../pages/Authentication/EmailVerify";
import Products from "../pages/Crm/Products";
import Orders from "../pages/Orders/Orders";
import OrderDetails from "../pages/Orders/OrderDetails";
import Integration from "../pages/Integration/KaspiAPIIntegration";

import Profit from "../pages/Pages/Analytics/Profit";
import Revolutions from "../pages/Pages/Analytics/Revolutions";
import BotIntegration from "../pages/Integration/BotIntegration";
import Pricelist from "../pages/Integration/BotIntegration/Pricelist";


const authProtectedRoutes = [
  // { path: "/returns", component: <Returns /> },
  { path: "/profit", component: <Profit /> },
  { path: "/revolutions", component: <Revolutions /> },

  { path: "/products", component: <Products /> },
  // { path: "/product-details/:_id", component: <EcommerceProductDetail /> },

  { path: "/add-product", component: <AddProduct/> },

  { path: "/expenses", component: <Expenses /> },

  // { path: "/returns/:orderId", component: <OrderDetails isReturns={true} /> },
  { path: "/returns", component: <Orders isReturns={true}/> },
  // { path: "/orders/:orderId", component: <OrderDetails /> },
  { path: "/orders", component: <Orders isReturns={false} /> },
  { path: "/orders_delivery", component: <Orders state={'DELIVERY'}/> },
  { path: "/orders_kaspi_delivery", component: <Orders state={'KASPI_DELIVERY'}/> },
    // Integrations
  { path: "/api-integration", component: <Integration /> },
  { path: "/bot-integration", component: <BotIntegration /> },
  { path: "/bot-integration-pricelist", component: <Pricelist /> },

  // { path: "/add-product", component: <AddProduct /> },
  { path: "/reception-new/:receptionId", component: <DetailsReception /> },
  { path: "/writeoffs-new/:receptionId", component: <DetailsReception /> },
  { path: "/receptions", component: <Reception /> },

  { path: "/storages", component: <Team /> },
  { path: "/storages/:storageId", component: <CrmContacts /> },
  { path: "/remains", component: <CrmContacts /> },

  //User Profile
  { path: "/profile", component: <Settings /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/products" />,
  },

  // <Route exact path="/" render={() => <Redirect to="/product" />} />
  //       <Route path="/product" component={Product} />
  //       <Route component={Error} />
  

];

const publicRoutes = [
  
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/email-verify", component: <EmailVerify /> },
  { path: "/error", component: <Basic404 /> },
  { path: "*", component: <Navigate to="/error" /> },

  //AuthenticationInner pages
  // { path: "/auth-signin-basic", component: <BasicSignIn /> },
  // { path: "/auth-signin-cover", component: <CoverSignIn /> },
  // { path: "/auth-signup-basic", component: <BasicSignUp /> },
  // { path: "/auth-signup-cover", component: <CoverSignUp /> },
  // { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  // { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  // { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  // { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  // { path: "/auth-logout-basic", component: <BasicLogout /> },
  // { path: "/auth-logout-cover", component: <CoverLogout /> },
  // { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  // { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  // { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  // { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },

  // { path: "/auth-404-cover", component: <Cover404 /> },
  // { path: "/auth-404-alt", component: <Alt404 /> },
  // { path: "/auth-500", component: <Error500 /> },
  // { path: "/pages-maintenance", component: <Maintenance /> },
  // { path: "/pages-coming-soon", component: <ComingSoon /> },

  // { path: "/landing", component: <OnePage /> },
  // { path: "/nft-landing", component: <NFTLanding /> },
  // { path: "/job-landing", component: <JobLanding /> },

  // { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  // { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  // { path: "/auth-offline", component: <Offlinepage /> },

];

export { authProtectedRoutes, publicRoutes };