import   React            from "react";
import { useGoogleLogin } from "@react-oauth/google";
import {api} from "../../../config";
import axios from "axios";
import {getMe, postJwtLogin} from "../../../helpers/backend_helper";
import {setAuthorization} from "../../../helpers/api_helper";
import {useNavigate} from "react-router-dom";
import google_logo from "../../../assets/images/social/google_logo.webp";
import {toast, ToastContainer} from "react-toastify";

const CustomGoogleLogin = () => {
  const navigate = useNavigate();

  const postConvertToken = async (googleAccessToken) => {
    try {
      axios.defaults.headers.common['Authorization'] = 'null'; // prevent Authorization Header
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          grant_type: "convert_token",
          client_id: api.CLIENT_PASSWORD_ID,
          client_secret: api.CLIENT_PASSWORD_SECRET,
          backend: "google-oauth2",
          token: googleAccessToken,
        }),
      };

      const response = await fetch(api.API_URL + "/auth/convert-token/", requestOptions);
      if (!response.ok) {
        // Handle non-200 responses (like 500, 404, etc.)
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data?.access_token) {
        setAuthorization(data.access_token);
        let userData = await getMe({});

        userData = JSON.stringify(userData);
        sessionStorage.setItem("authUser", userData);
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        console.log('Logged in successfully!');
        navigate('/');
      } else {
        console.error('ERROR LOGIN data.access_token');
      }
    } catch (error) {
      toast.error('Не удалось установить соединение с сервером. Попробуйте позже.', {
        position: 'top-right',
      });
    }
  };


  //access_token
  //refresh_token
  const login = useGoogleLogin({
    onSuccess: function (codeResponse) {
      postConvertToken(codeResponse.access_token);
    },
    flow: "implicit",
  });

  return (
    <div>
      <button
        type='button'
        onClick={() => login()}
        className="btn btn-outline-success w-100 mt-3"
      >
        Войти через Google
        <img width="15px" className="mx-2" src={google_logo} alt="google_logo"/>
      </button>
      <ToastContainer closeButton={false} limit={1} />
    </div>
  );
};

export default CustomGoogleLogin;
