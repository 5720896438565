import React, { useState, useRef, useEffect } from 'react';
import '../../../Components/Common.css';
import styled from 'styled-components';
import Loader from "../../../Components/Common/Loader";

const CustomTable = styled.table`
  td:first-child,
  th:first-child {
    padding-left: 10px !important;
    padding-right: 5px !important;
  }
`;

function ProductDropdown({ products, productsLoading, onProductSelect, selectedProducts, fetchOrders }) {
    const [searchValue, setSearchValue] = useState('');
    const resultsRef = useRef(null);
    const containerRef = useRef(null);

    const filteredProducts = products.filter(
        (product) =>
            product.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            product.code.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleCheckboxChange = (product) => {
        let nowSelected;
        if (selectedProducts.includes(product.id)) {
            nowSelected = selectedProducts.filter(id => id !== product.id);
            // onProductSelect(selectedProducts.filter(id => id !== product.id)); // Unselect product
        } else {
            nowSelected = [...selectedProducts, product.id];
            // onProductSelect([...selectedProducts, product.id]); // Select product
        }
        onProductSelect(nowSelected);
        fetchOrders(1, nowSelected);
    };

    // Handle clicks outside of the component
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setSearchValue(''); // Reset search value
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div ref={containerRef} style={{ position: 'relative', maxWidth: '100%' }}>
            <input
                className='form-select'
                style={{ width: '100%', maxWidth: '100%' }}
                type="text"
                placeholder="Выбрать товары..."
                value={searchValue}
                onChange={handleSearchChange}
            />
            {(searchValue || productsLoading) && (
                <div
                    ref={resultsRef}
                    className="results"
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        maxWidth: '400px',
                        zIndex: 1000,
                        maxHeight: '288px', // Limit the height of the dropdown
                        overflowY: 'auto',
                        backgroundColor: 'white',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #ddd',
                    }}
                >
                    {productsLoading ?
                        searchValue && (
                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                <Loader />
                            </div>
                        ) : filteredProducts.length > 0 ? (
                            <CustomTable className="table-nowrap table table-hover">
                                <tbody style={{ verticalAlign: 'baseline' }}>
                                {filteredProducts.map((product) => (
                                    <tr key={product.id} style={{ fontSize: '14px', cursor: 'pointer' }}>
                                        <td>
                                            <input
                                                style={{width: '15px', height: '15px', verticalAlign: 'middle'}}
                                                type="checkbox"
                                                checked={selectedProducts.includes(product.id)}
                                                onChange={() => handleCheckboxChange(product)}
                                            />
                                        </td>
                                        <td style={{ lineHeight: 1, wordWrap: 'break-word', whiteSpace: 'normal', minWidth: '197px' }}
                                            onClick={() => handleCheckboxChange(product)}>
                                            {product.title}<br/><span style={{fontSize: '12px'}}>{product.code}</span>
                                        </td>
                                        <td>
                                            {product.image || product?.url_image ? (
                                                <div style={{ height: '30px', textAlign: 'center', width: '35px' }}
                                                     onClick={() => handleCheckboxChange(product)}>
                                                    <img
                                                        src={product.image || product?.url_image}
                                                        alt=" "
                                                        style={{ width: 'auto', maxWidth: '100%', maxHeight: '100%' }}
                                                    />
                                                </div>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </CustomTable>
                        ) : (
                            <div style={{ padding: '10px' }}>Ничего не найдено</div>
                        )}
                </div>
            )}
        </div>
    );
}

export default ProductDropdown;
